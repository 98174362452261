import type { CommonResponse } from 'types/api'
import type { ResponseSupportingDocument } from 'types/kinerja-pelaksanaan'

export const dummySupportingDocuments: CommonResponse<ResponseSupportingDocument> =
  {
    data: {
      documents: [
        {
          type: 'KOSP',
          mediaManagerId: 'id1',
          fileUrl:
            'https://dummy.com/guru-cp-skp/media-managers/SUPPORTING_DOCUMENT/id/doc.pdf',
          fileName: 'Modul Ajar Bahasa Indonesia - Teks Prosedur - Fase C.pdf',
        },
        {
          type: 'PLANNING',
          mediaManagerId: 'id2',
          fileUrl:
            'https://dummy.com/guru-cp-skp/media-managers/SUPPORTING_DOCUMENT/id/doc.pdf',
          fileName: 'Modul Ajar Bahasa Indonesia - Teks Prosedur - Fase C.pdf',
        },
        {
          type: 'REPORT',
          mediaManagerId: 'id2',
          fileUrl:
            'https://dummy.com/guru-cp-skp/media-managers/SUPPORTING_DOCUMENT/id/doc.pdf',
          fileName: 'Modul Ajar Bahasa Indonesia - Teks Prosedur - Fase C.pdf',
        },
        {
          type: 'ATTENDANCE',
          mediaManagerId: 'id2',
          fileUrl:
            'https://dummy.com/guru-cp-skp/media-managers/SUPPORTING_DOCUMENT/id/doc.pdf',
          fileName: 'Modul Ajar Bahasa Indonesia - Teks Prosedur - Fase C.pdf',
        },
      ],
    },
    meta: {},
  }
