import Head from 'next/head'

interface MetaTagsProps {
  metaTags?: { [key: string]: string }
  pageTitle?: string
  pageDescription?: string
}

export const MetaTags = ({
  metaTags,
  pageTitle,
  pageDescription,
}: MetaTagsProps) => {
  return (
    <Head>
      {!!pageTitle && <title>{pageTitle}</title>}
      {!!pageDescription && (
        <meta name="description" content={pageDescription}></meta>
      )}
      {!!metaTags && (
        <>
          {Object.entries(metaTags).map((entry) => (
            <meta
              key={entry[0]}
              property={entry[0]}
              name={entry[0].replace(/(.*)-/, '')}
              content={entry[1].toString()}
            />
          ))}
          <meta name="twitter:card" content="summary_large_image"></meta>
        </>
      )}
    </Head>
  )
}
export default MetaTags
