import clsx from 'clsx'
import { useRouter } from 'next/router'

import type { FC, SVGProps } from 'react'

import BadgeIcon from 'assets/icons/badge.svg'

export interface NavItem {
  href?: string
  component?: React.FC
  icon: FC<SVGProps<SVGSVGElement>>
  text: string
  disabled?: boolean
  badge?: number
  hasBadge?: boolean
}

interface BottomNavProps extends React.HTMLAttributes<HTMLDivElement> {
  navItems: NavItem[]
  disabled?: boolean
  onNavigate?: (href: string) => void
}

interface IconNavProps {
  disabled: boolean
  item: NavItem
}

const IconNav = ({ disabled, item }: IconNavProps) => {
  const router = useRouter()
  const iconSize = 24

  return (
    <div className="h-full flex flex-col items-center justify-center">
      {item.icon && (
        <div className="relative">
          <item.icon
            width={iconSize}
            height={iconSize}
            className={clsx(
              disabled
                ? 'text-gray-300'
                : router.pathname === item.href
                ? 'text-primary'
                : 'text-gray-500'
            )}
          />
          {Number(item.badge) > 0 && (
            <span className="text-[10px] leading-[10px] py-[1px] px-1 font-bold rounded-lg bg-[#F66951] text-white absolute top-0 -right-1 border border-solid border-white">
              {item.badge}
            </span>
          )}
          {item.hasBadge && (
            <span className="absolute top-0 -right-1">
              <BadgeIcon />
            </span>
          )}
        </div>
      )}
      <p
        className={clsx(
          'text-xs mt-1 text-gray-500',
          router.pathname === item.href && 'text-blue-700 font-semibold'
        )}
      >
        {item.text}
      </p>
    </div>
  )
}

export const BottomNav = ({
  className,
  navItems,
  disabled,
  onNavigate,
  ...props
}: BottomNavProps) => {
  const router = useRouter()

  const handleNavigation = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string
  ) => {
    e.preventDefault()

    if (!disabled) {
      router.push(href)
    }

    if (onNavigate) {
      onNavigate(href)
    }
  }

  return (
    <nav
      className={clsx(
        'fixed z-20 bottom-0 max-w-xl w-full bg-white h-14 mx-auto px-4 shadow-top',
        className
      )}
      {...props}
    >
      <ul className="flex flex-row w-full h-full">
        {navItems.map((item, idx) => (
          <li className="block h-full flex-1" key={`nav-item-${idx}`}>
            {item.component ? (
              <item.component>
                <IconNav item={item} disabled={disabled} />
              </item.component>
            ) : (
              <a
                href={item.href}
                onClick={(e) => handleNavigation(e, item.href)}
                aria-disabled={disabled}
              >
                <IconNav item={item} disabled={disabled} />
              </a>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default BottomNav
