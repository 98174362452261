export const isBrowser: boolean = typeof window !== 'undefined'

export const isNavigator: boolean = typeof navigator !== 'undefined'

export const userAgent: string = isNavigator ? navigator?.userAgent : ''

export function isWebview(): boolean {
  const userAgent = isNavigator ? navigator?.userAgent : ''

  return /androidmobile/i.test(userAgent)
}
