export const APP_LOGIN_STORAGE_KEY = 'app-login'

export const YOUTUBE_LOGIN_STORAGE_KEY = 'youtube-login'

export const USER_ID_STORAGE_KEY = 'user-id'

export const GURU_TOKEN_GOOGLE_OAUTH_CONFIG = {
  clientId: process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID,
  responseType: 'id_token permission',
  cookiePolicy: 'single_host_origin',
  prompt: 'consent',
  scope: 'email profile openid',
}

export const SATU_EVENT_CONFIG = {
  enabled: process.env.NEXT_PUBLIC_SATU_EVENT_ENABLED === 'true',
  debugEnabled: process.env.NEXT_PUBLIC_SATU_EVENT_DEBUG === 'true',
  url: process.env.NEXT_PUBLIC_SATU_EVENT_API_URL,
  username: process.env.NEXT_PUBLIC_SATU_EVENT_USERNAME,
  password: process.env.NEXT_PUBLIC_SATU_EVENT_PASSWORD,
  mengajar_username: process.env.NEXT_PUBLIC_SATU_EVENT_MENGAJAR_USERNAME,
  mengajar_password: process.env.NEXT_PUBLIC_SATU_EVENT_MENGAJAR_PASSWORD,
}
