import type { CommonResponse } from 'types/api'
import type {
  ResponsePelaksanaanProgress,
  TPelaksanaanAppealableStep,
} from 'types/kinerja-pelaksanaan'

export const dummyPelaksanaanProgress: CommonResponse<ResponsePelaksanaanProgress> =
  {
    data: {
      contentId: 'dummy-content-id',
      subIndicatorName: 'Instruksi Pembelajaran',
      subIndicatorDefinition:
        'Upaya penjelasan terstruktur tentang konsep dan aktivitas pembelajaran disertai demonstrasi, ilustrasi, atau contoh yang relevan dan kontekstual untuk peningkatan efektivitas pembelajaran',
      qualityTarget:
        'Tingkat upaya penjelasan terstruktur tentang konsep dan aktivitas pembelajaran disertai demonstrasi, ilustrasi, atau contoh yang relevan dan kontekstual untuk peningkatan efektivitas pembelajaran',
      state: 'OBSERVATION_IMPLEMENTATION',
      appealState: '' as TPelaksanaanAppealableStep,
      latestFormState: 'NOT_CREATED',
      period: 1,
      year: 2024,
      progressPercentage: 25,
      realisasiState: {
        additionalTask: 'NOT_COMPLETED',
        competencyImprovement: 'NOT_COMPLETED',
      },
      supervisor: {
        ptkId: 'dummy-ptkid',
        userId: 'dummy-ptkid',
        name: 'dummy-superior-name',
        role: 'KS_DEFINITIF',
        confirmationStatus: 'CONFIRMED',
        pltAndGuruInSameSchool: false,
      },
    },
    meta: {},
  }
