import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { historyUtils } from '@wartek-id/fe-toolbox'

import {
  Header as HeaderComposition,
  HeaderTitle,
  HeaderLeftAction,
  HeaderRightAction,
} from './HeaderComposition'

import type { BackgroundColor } from './HeaderComposition'

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  titlePosition?: 'left' | 'center'
  subtitle?: string
  backHref?: string
  hasBackButton?: boolean
  variant?: BackgroundColor
  replace?: boolean
  iconRightSrc?: string
  iconLeftSrc?: string
  leftChild?: React.ReactNode
  rightChild?: React.ReactNode
  onLeftActionClick?: (...args: any[]) => any
  onRightActionClick?: (...args: any[]) => any
  rightDropdownItemComponents?: React.ReactNode[]
  bordered?: boolean
  hasOrnament?: boolean
  pageProps?: Record<string, unknown>
}

export const Header = ({
  title,
  titlePosition = 'center',
  subtitle,
  backHref,
  hasBackButton = false,
  variant = 'default',
  replace = true,
  iconLeftSrc = '/icon/arrow-left.svg',
  iconRightSrc,
  leftChild,
  rightChild,
  onLeftActionClick,
  onRightActionClick = () => {},
  bordered = true,
  hasOrnament = false,
  ...props
}: HeaderProps) => {
  const router = useRouter()

  return (
    <HeaderComposition
      hasBorder={bordered}
      hasOrnament={hasOrnament}
      backgroundColor={variant}
      {...props}
    >
      {hasBackButton &&
        ((backHref && (
          <HeaderLeftAction>
            <Link href={backHref} replace={replace}>
              <a href={backHref}>
                <Image
                  src={iconLeftSrc}
                  width={24}
                  height={24}
                  alt="back button"
                />
              </a>
            </Link>
          </HeaderLeftAction>
        )) || (
          <HeaderLeftAction>
            <button
              aria-label="header-bar-left-action"
              onClick={() => {
                if (typeof onLeftActionClick === 'function') {
                  onLeftActionClick()
                } else {
                  historyUtils.backToApp(router.back)
                }
              }}
            >
              {leftChild !== undefined ? (
                <>{leftChild}</>
              ) : (
                <Image
                  src={iconLeftSrc}
                  width={24}
                  height={24}
                  alt="back button"
                />
              )}
            </button>
          </HeaderLeftAction>
        ))}

      {title && (
        <HeaderTitle>
          <div
            className={clsx(
              titlePosition === 'center' && 'flex flex-col items-center',
              titlePosition === 'left' && 'absolute left-[60px]'
            )}
          >
            <div className="text-base font-semibold">{title}</div>
            {subtitle && (
              <span className="text-sm text-type-basic-secondary">
                {subtitle}
              </span>
            )}
          </div>
        </HeaderTitle>
      )}
      {(iconRightSrc || rightChild) && (
        <HeaderRightAction>
          <button onClick={onRightActionClick}>
            {rightChild !== undefined ? (
              <>{rightChild}</>
            ) : (
              <Image
                src={iconRightSrc}
                width={24}
                height={24}
                alt="share button"
              />
            )}
          </button>
        </HeaderRightAction>
      )}
    </HeaderComposition>
  )
}

export default Header
