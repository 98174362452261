import React from 'react'
import flattenNodes from 'react-keyed-flatten-children'
import clsx from 'clsx'

import { assetUrl } from 'utils/url'

export type BackgroundColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'darkBlue'
  | 'milBlue'
  | 'milGray'

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  backgroundColor?: BackgroundColor
  hasBorder?: boolean
  hasOrnament?: boolean
}

export function HeaderLeftAction({ children }) {
  return <div className="absolute z-10 leading-3 left-3">{children}</div>
}

export function HeaderRightAction({ children }) {
  return <div className="absolute z-10 leading-3 right-3">{children}</div>
}

export function HeaderTitle({ children }) {
  return children
}

export function Header({
  backgroundColor = 'default',
  hasBorder = true,
  hasOrnament = false,
  className,
  children,
  ...props
}: HeaderProps) {
  const baseClasses =
    'flex justify-center items-center h-14 px-3 fixed w-full max-w-xl z-10 top-0'

  const variants = {
    default: 'bg-white text-black',
    primary: 'bg-primary text-white',
    secondary: 'bg-secondary text-white',
    transparent: 'bg-transparent text-black',
    darkBlue: 'bg-[#006ED3] text-white',
    milGray: 'bg-gray-5 text-type-basic',
    milBlue: 'bg-expressive-dark-blue text-white',
  }

  const classes = clsx(
    baseClasses,
    variants[backgroundColor],
    hasBorder && 'border-b border-gray-300',
    className
  )

  return (
    <header className={classes} {...props}>
      {React.Children.map(flattenNodes(children), (child) => {
        if (typeof child === 'object') {
          if (child.type === HeaderLeftAction) {
            return <HeaderLeftAction {...child.props} />
          }
          if (child.type === HeaderRightAction) {
            return <HeaderRightAction {...child.props} />
          }
          if (child.type === HeaderTitle) {
            return <HeaderTitle {...child.props} />
          }
        }
        return null
      })}

      {hasOrnament && (
        <>
          <img
            className="absolute top-0 left-0 z-0"
            src={assetUrl('/images/layout/header-ornament-left.svg')}
            width={144}
            height={56}
            alt=""
          />
          <img
            className="absolute bottom-0 right-0 z-0"
            src={assetUrl('/images/layout/header-ornament-right-alt.svg')}
            width={196}
            height={32}
            alt=""
          />
        </>
      )}
    </header>
  )
}
