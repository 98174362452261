import React from 'react'
import clsx from 'clsx'

import type { SpinnerProps } from '@wartek-id/spinner'
import { Spinner } from '@wartek-id/spinner'

interface LoadingStateProps extends React.HTMLAttributes<HTMLDivElement> {
  withHeader?: boolean
  disableWrapper?: boolean
  blockClassName?: string
  spinnerSize?: SpinnerProps['size']
}

export function LoadingState({
  children,
  withHeader = true,
  disableWrapper = false,
  blockClassName = '',
  spinnerSize = 'md',
  className,
  ...props
}: LoadingStateProps) {
  const containerClasses = clsx(
    'flex items-center justify-center px-5 text-sm',
    withHeader ? 'h-full--without-header' : 'h-full',
    className
  )

  return (
    <div {...props} className={containerClasses}>
      <div
        className={clsx(
          'flex items-center justify-center max-w-[288px]',
          blockClassName
        )}
      >
        <Spinner size={spinnerSize} />

        {children && <>{disableWrapper ? children : <p>{children}</p>}</>}
      </div>
    </div>
  )
}
