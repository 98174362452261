import type { CommonResponse } from 'types/api'
import type { ResponseEvaluationDetail } from 'types/kinerja-pelaksanaan'

export const dummyEvaluationDetail: CommonResponse<ResponseEvaluationDetail> = {
  data: {
    userId: 'dummy-1',
    name: 'Sri Hariyati',
    class: 'Kelas 2',
    subject: 'Matematika',
    employeeStatus: 'PNS',
    observation: {
      date: 'Senin, 1 Maret 2024',
      state: 'DOCUMENT_PREPARATION',
      progress: 0,
      total: 3,
      // gradingState: 'Rating observasi',
    },
    // ptkId: 'testing_cgp',
    workBehavior: {
      state: 'NOT_CREATED',
      progress: 0,
      total: 1,
      result: '',
      // gradingState: 'Belum dinilai',
    },
    document: {
      state: 'Sudah lengkap',
      progress: 2,
      total: 2,
    },
    performancePractice: {
      state: 'NOT_CREATED',
      result: '',
    },
    predicateRecommendation: '',
  },
  meta: {},
}
