import Link from 'next/link'
import clsx from 'clsx'

import ArrowLeftIcon from 'assets/icons/arrow-left.svg'

export interface LayoutWithHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  title: string
  href?: string
  onClickBack?: () => void
  headerVariant?: 'basic' | 'styled'
}

export const LayoutWithHeader = ({
  children,
  title,
  href,
  onClickBack,
  className,
  headerVariant = 'basic',
  ...props
}: LayoutWithHeaderProps) => {
  return (
    <div
      className={clsx('max-w-xl m-auto relative', className)}
      style={{
        minHeight: 'calc(100vh - 3.5rem)',
      }}
    >
      <header
        className={clsx(
          'flex justify-center items-center h-14 px-3 fixed w-full max-w-xl z-[1] top-0',
          {
            'border-b border-gray-300 bg-white text-black':
              headerVariant === 'basic',
          },
          {
            'text-white bg-login': headerVariant === 'styled',
          }
        )}
        {...props}
      >
        {href && (
          <Link href={href}>
            <a href={href} className="absolute left-3">
              <ArrowLeftIcon fill="currentColor" aria-hidden />
              <span className="sr-only">Kembali</span>
            </a>
          </Link>
        )}
        {onClickBack && (
          <button className="absolute left-3" onClick={onClickBack}>
            <ArrowLeftIcon fill="currentColor" aria-hidden />
            <span className="sr-only">Kembali</span>
          </button>
        )}
        <h1 className="text-base font-semibold">{title}</h1>
      </header>
      <div className="mt-14">{children}</div>
    </div>
  )
}

export default LayoutWithHeader
