import type { CommonResponse } from 'types/api'
import type { ResponseWorkBehavior } from 'types/kinerja-pelaksanaan'

export const dummyWorkBehavior: CommonResponse<ResponseWorkBehavior> = {
  data: {
    detail: {
      aspects: [
        {
          aspectId: 'dummy-aspect-id-1',
          aspectValue: 'Berorientasi Pelayanan',
          indicatorId: 'dummy-indicator-id-1',
          indicatorValue:
            'Memahami kebutuhan peserta didik dan berusaha memenuhinya',
          expectationId: 'dummy-expectation-id-1',
          expectationValue: 'Memenuhi kebutuhan peserta didik secara responsif',
          impact: 0,
          implementation: 0,
        },
        {
          aspectId: 'dummy-aspect-id-2',
          aspectValue: 'Akuntabel',
          indicatorId: 'dummy-indicator-id-2',
          indicatorValue:
            'Melaksanakan tugas dengan jujur, bertanggung jawab, cermat, disiplin, dan berintegritas tinggi',
          expectationId: 'dummy-expectation-id-2',
          expectationValue: 'Memenuhi janji dan komitmen terkait pekerjaan',
          impact: 0,
          implementation: 0,
        },
        {
          aspectId: 'dummy-aspect-id-3',
          aspectValue: 'Kompeten',
          indicatorId: 'dummy-indicator-id-3',
          indicatorValue:
            'Menunjukkan penguasaan kompetensi yang memadai dalam melakukan kinerja',
          expectationId: 'dummy-expectation-id-3',
          expectationValue:
            'Menunjukkan kompetensi dalam bekerja sehingga menjadi teladan bagi rekan sejawat',
          impact: 0,
          implementation: 0,
        },
        {
          aspectId: 'dummy-aspect-id-4',
          aspectValue: 'Harmonis',
          indicatorId: 'dummy-indicator-id-4',
          indicatorValue:
            'Menghargai setiap warga satuan pendidikan apapun latar belakangnya',
          expectationId: 'dummy-expectation-id-4',
          expectationValue:
            'Berlaku adil kepada setiap orang tanpa memandang, kedudukan, jabatan, latar belakang, suku, agama, ras dan gender',
          impact: 0,
          implementation: 0,
        },
        {
          aspectId: 'dummy-aspect-id-5',
          aspectValue: 'Loyal',
          indicatorId: 'dummy-indicator-id-5',
          indicatorValue:
            'Memegang teguh moral dan mematuhi peraturan perundang-undangan yang berlaku',
          expectationId: 'dummy-expectation-id-5',
          expectationValue:
            'Menghindari tindakan, ucapan, perbuatan yang menjurus pada perpecahan',
          impact: 0,
          implementation: 0,
        },
        {
          aspectId: 'dummy-aspect-id-6',
          aspectValue: 'Adaptif',
          indicatorId: 'dummy-indicator-id-6',
          indicatorValue:
            'Menyesuaikan diri secara cepat dalam menghadapi dinamika pembelajaran di kelas',
          expectationId: 'dummy-expectation-id-6',
          expectationValue: 'Menyesuaikan diri di berbagai lingkungan kerja',
          impact: 0,
          implementation: 0,
        },
        {
          aspectId: 'dummy-aspect-id-7',
          aspectValue: 'Kolaboratif',
          indicatorId: 'dummy-indicator-id-7',
          indicatorValue:
            'Memberi kesempatan kepada warga satuan pendidikan dan masyarakat atau duni usaha untuk berkontribusi bagi tujuan pembelajaran yang berpusat pada peserta didik',
          expectationId: 'dummy-expectation-id-7',
          expectationValue:
            'Menerima pendapat dan saran dalam menyelesaikan tugas',
          impact: 0,
          implementation: 0,
        },
      ],
    },
    state: 'NOT_CREATED',
  },
  meta: {},
}
