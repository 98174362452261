const DEFAULT_HTTP_TIMEOUT = 10000 // 10 seconds

type TRequestOptions = RequestInit & {
  timeout: number
}

export default async function fetchWithTimeout(
  url: string,
  options?: TRequestOptions
): Promise<Response> {
  const { timeout = DEFAULT_HTTP_TIMEOUT } = options
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), timeout)

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    })
    return response?.json?.()
  } catch (error) {
    console.error(error)
  } finally {
    clearTimeout(timeoutId)
  }
}
