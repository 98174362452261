import type { CommonResponse } from 'types/api'
import type { ResponsePelaksanaanTargetPerilaku } from 'types/kinerja-pelaksanaan'

export const dummyTargetPerilaku: CommonResponse<
  ResponsePelaksanaanTargetPerilaku[]
> = {
  data: [
    {
      id: 'test-id-1',
      behavioralTarget:
        'Guru memberikan penjelasan yang mudah sesuai pemahaman awal peserta didik',
      recommendedBehaviors: [
        'Guru menampilkan visualisasi atau ilustrasi yang memudahkan pemahaman peserta didik',
        'Guru menjelaskan konsep yang sulit dengan bahasa yang mudah dipahami',
        'Guru menjelaskan konsep disesuaikan hasil asesmen awal',
      ],
      prohibitedBehaviors: [
        'Guru menampilkan visualisasi atau ilustrasi menarik tapi tidak terkait penjelasannya',
        'Guru menjelaskan konsep yang sulit dengan istilah-istilah baru yang sulit dipahami',
        'Guru menjelaskan konsep hanya mengacu pada buku/perangkat ajar',
      ],
    },
    {
      id: 'test-id-2',
      behavioralTarget:
        'Guru memberikan contoh yang kontekstual  dan relevan dengan keseharian peserta didik',
      recommendedBehaviors: [
        'Guru menjelaskan konsep disertai dengan contoh yang familiar dengan kehidupan peserta didik',
        'Guru menjelaskan konsep dikaitkan dengan isu atau topik sehari-hari yang relevan bagi peserta didik',
        'Guru memberi kesempatan pada peserta didik berbagi pengalaman yang relevan',
      ],
      prohibitedBehaviors: [
        'Guru menjelaskan konsep disertai contoh yang hanya dipahami oleh guru atau orang dewasa',
        'Guru menjelaskan konsep tanpa dikaitkan dengan kehidupan sehari-hari',
        'Guru menggunakan contoh yang sama berulang-ulang',
      ],
    },
    {
      id: 'test-id-3',
      behavioralTarget:
        'Guru menyampaikan penjelasan secara terstruktur dan logis',
      recommendedBehaviors: [
        'Guru menjelaskan konsep secara runtut dari tujuan, konsep inti dan kesimpulan',
        'Guru menjelaskan materi baru dihubungkan dengan materi sebelumnya',
        'Guru memberi penjelasan dan bimbingan langkah demi langkah disertai dengan demonstrasi',
      ],
      prohibitedBehaviors: [
        'Guru memberi instruksi yang terlalu umum sehingga peserta didik kesulitan mengikuti langkah-langkahnya',
        'Guru menjelaskan konsep tidak runtut, melompat-lompat antar topik tanpa menjelaskan keterkaitannya',
        'Guru tidak mengulang bagian penting dari penjelasannya',
      ],
    },
  ],
  meta: {},
}
