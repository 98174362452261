import axios from 'axios'
import { v4 as uuid } from 'uuid'
import * as Sentry from '@sentry/nextjs'
import { useQuery } from 'react-query'

import { EXPERIMENT_CONFIG } from 'configs/experiment'

import type { UseQueryOptions } from 'react-query'

const logPrefix = '[A/B Test] Guru-FE Experiment API: '

const log = (message: string, type = 'log') => {
  if (EXPERIMENT_CONFIG.debug) {
    if (type === 'error') console.error(logPrefix, message)
    // eslint-disable-next-line no-console
    else console.log(logPrefix, message)
  }
}

const logError = (message: string) => {
  // Only log errors if debug is enabled and not on production
  if (EXPERIMENT_CONFIG.debug) {
    // For non production environments, log the errors to the browser
    if (process.env.NODE_ENV !== 'production') {
      log(message, 'error')
    }

    // For production environment, send to sentry
    else {
      Sentry.captureMessage(`${logPrefix}: ${message}`, 'error')
    }
  }

  return null
}

export function useAPIParticipateAllExperiments(
  userId: string,
  deviceId: string,
  opts?: UseQueryOptions<any>
) {
  return useQuery(
    ['guru-frontend-all-experiments'],
    async () => {
      try {
        const response = await axios.post(
          `${EXPERIMENT_CONFIG.baseUrl}/experiments/participate`,
          {
            deviceId,
            userId,
          },
          {
            headers: {
              Authorization: `Basic ${EXPERIMENT_CONFIG.credentials}`,
              'X-Request-ID': uuid(),
            },
          }
        )
        return response?.data ?? {}
      } catch (e) {
        const message = e?.message ?? 'Unknown error'
        logError(message)
        return { error: message }
      }
    },
    {
      onError: (error) => Sentry.captureException(error),
      ...opts,
    }
  )
}
