import { useEffect } from 'react'

import { APP_DEVICE_ID_STORAGE_KEY } from 'configs/android'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

export default function useAppDeviceId(appDeviceID) {
  const [localAppDeviceId, setAppDeviceId] = useLocalStorage(
    APP_DEVICE_ID_STORAGE_KEY,
    null
  )

  useEffect(() => {
    if (localAppDeviceId !== appDeviceID) {
      setAppDeviceId(appDeviceID ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDeviceID])
}
