import { useQuery } from 'react-query'
import axios from 'axios'

import { baseUrl } from 'configs/api'
import { api } from 'utils/api'
import { useTogglesStore } from 'stores/toggles'

import type { TToggleKeyValuePair } from 'configs/toggle-feature'
import type { AxiosResponse } from 'axios'
import type { Toggle } from 'types/entities'
import type { ToggleKey } from 'types/toggle'

export interface ResponseToggle {
  data: Toggle[]
  meta: any
}

export function useAPIGetAllToggles() {
  return useQuery(
    ['/teachers/v1alpha1/toggles'],
    async (): Promise<ResponseToggle> => {
      const response = await api<ResponseToggle>('/teachers/v1alpha1/toggles')
      return response
    }
  )
}

export interface ResponseToggleById {
  data: Toggle
  meta: any
}
export function useAPIGetToggleById(key: ToggleKey) {
  return useQuery(
    ['/teachers/v1alpha1/toggles', key],
    async (): Promise<ResponseToggleById> => {
      const response = await api<ResponseToggleById>(
        `/teachers/v1alpha1/toggles/${key}`
      )
      return response
    }
  )
}

export function useAPIUnauthorizedGetToggleById(key: ToggleKey) {
  return useQuery(
    ['/teachers/v1alpha1/toggles', key],
    async (): Promise<ResponseToggleById> => {
      try {
        const response = await axios.get<ResponseToggleById>(
          `${baseUrl}/teachers/v1alpha1/toggles/${key}`
        )
        return response.data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  )
}

export function useAPIGetAllServiceToggle(enabled: boolean = true) {
  const setToggles = useTogglesStore((state) => state.setToggles)
  return useQuery<AxiosResponse<TToggleKeyValuePair>>(
    ['get-all-service-toggle'],
    () => axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/get-toggle/all`),
    {
      onSuccess: (data) => {
        setToggles(data?.data)
      },
      useErrorBoundary: false,
      enabled,
    }
  )
}
