import type { CommonResponse } from 'types/api'
import type {
  IReflectionImprovementTypes,
  ResponseFollowUpEvaluationDocument,
} from 'types/kinerja-pelaksanaan'

export const dummyFollowUpEvaluationDocument: CommonResponse<ResponseFollowUpEvaluationDocument> =
  {
    data: {
      detail: {
        reflectionImprovement: '' as IReflectionImprovementTypes,
        reflectionNotes: '',
      },
      state: 'NOT_CREATED',
    },
    meta: {},
  }
