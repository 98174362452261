import create from 'zustand'
import { persist } from 'zustand/middleware'

import { devtools } from 'utils/zustand'

import type { Filters } from 'types/entities'
import type { State } from 'zustand'

export type TFilterStore = State & {
  filters?: Filters
  isLoading?: boolean
  setFilters?: (filters: Filters) => void
  setIsLoading?: (isLoading: boolean) => void
}

const initialState: Partial<TFilterStore> = {
  filters: null,
  isLoading: false,
}

export const useFiltersStore = create<TFilterStore>(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setFilters: (filters) =>
          set({
            filters,
          }),
        setIsLoading: (isLoading) => set({ isLoading }),
      }),
      {
        name: 'filters',
        partialize: ({ filters }) => ({
          filters,
        }),
      }
    )
  )
)
