import { useState } from 'react'

import { Button } from '@wartek-id/button-v1'
import { Checkbox } from '@wartek-id/checkbox-v1'
import type { DialogProps } from '@wartek-id/dialog-v1'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
} from '@wartek-id/dialog-v1'
import { Icon } from '@wartek-id/icon-v2'
import { Text } from '@wartek-id/text-v1'

import useSKPPelaksanaanTrackEvent from 'app/KinerjaPage/hooks/useSKPPelaksanaanTrackEvent'

import type { ScreenName } from 'app/KinerjaPage/hooks/useSKPPelaksanaanTrackEvent'

type GuidePerilakuKerjaDialogProps = Omit<DialogProps, 'children'> & {
  hasCheckbox?: boolean
  onConfirm?: (isChecked?: boolean) => void
  screenName?: ScreenName
  eventTrigger?: ScreenName
}

export function GuidePerilakuKerjaDialog({
  hasCheckbox,
  onConfirm,
  screenName,
  eventTrigger,
  ...dialogProps
}: GuidePerilakuKerjaDialogProps) {
  const [isChecked, setIsChecked] = useState(false)
  const { trackEvent } = useSKPPelaksanaanTrackEvent()

  return (
    <Dialog closeOnOverlayClick={false} size="md" {...dialogProps}>
      <DialogOverlay />
      <DialogContent>
        <DialogHeader
          borderless
          title="Sebelum lanjut, perhatikan langkah menilai:"
        />
        <div className="pt-6 px-6 space-y-4">
          <ul className="space-y-4">
            <li className="flex space-x-3">
              <div className="w-6 h-6 rounded-full bg-gray-80 flex justify-center items-center flex-shrink-0">
                <Text variant="body-sm" color="inverse">
                  1
                </Text>
              </div>
              <div className="space-y-1">
                <Text variant="action">
                  Periksa pilihan indikator dan perwujudan guru
                </Text>
                <Text color="subdued">
                  Ingat-ingat lagi bagaimana proses guru melaksanakan
                  pilihannya.
                </Text>
              </div>
            </li>
            <li className="flex space-x-3">
              <div className="w-6 h-6 rounded-full bg-gray-80 flex justify-center items-center flex-shrink-0">
                <Text variant="body-sm" color="inverse">
                  2
                </Text>
              </div>
              <div className="space-y-1">
                <Text variant="action">
                  Berikan nilai sesuai pantauan dan pertimbangan Anda
                </Text>
                <Text color="subdued">
                  Nilai masing-masing aspek akan digabung dan otomatis dihitung
                  untuk menghasilkan Rating Perilaku Kerja.
                </Text>
              </div>
            </li>
          </ul>

          <div className="flex space-x-3 p-3 items-center bg-surface-informational-subdued rounded">
            <Icon>information_outlined</Icon>
            <Text>
              Jenis Rating: Di Bawah Ekspektasi, Sesuai Ekspektasi, dan Di Atas
              Ekspektasi
            </Text>
          </div>
          {hasCheckbox && (
            <Checkbox
              labelPosition="center"
              checked={isChecked}
              controlled
              onChange={() => setIsChecked(!isChecked)}
            >
              Saya mengerti, tidak perlu tampilkan halaman ini lagi.
            </Checkbox>
          )}
        </div>
        <DialogFooter borderless className="mb-2">
          <Button
            color="blue"
            size="lg"
            className="mx-auto"
            onClick={() => {
              trackEvent({
                activity_type:
                  screenName === 'ks_perilaku_kerja'
                    ? 'penilaian_ks_perilaku_click_popup_lanjut_panduan_menilai'
                    : 'penilaian_ks_rincian_penilaian_click_lanjut_panduan_perilaku_kerja',
                screen_name: screenName || 'ks_rincian_penilaian',
                event_trigger: eventTrigger || 'ks_periksa_skp',
              })

              onConfirm?.(isChecked)
            }}
          >
            Lanjut
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
