// for some reason, using error for throwing error will always
// result in uncaught error, thus we don't extend Error class here

export class CustomError {
  readonly title: string
  readonly description: string
  readonly apiPath: string
  readonly originalError: any

  constructor({
    title,
    description,
    apiPath,
    originalError,
  }: {
    title: string
    description: string
    apiPath: string
    originalError: any
  }) {
    this.title = title
    this.description = description
    this.apiPath = apiPath
    this.originalError = originalError
  }
}

export class SessionError extends CustomError {
  constructor(apiPath: string, originalError: any) {
    const title = 'Sesi Anda berakhir'
    const description =
      'Login untuk melanjutkan dengan ketuk tombol di bawah ini.'
    super({ title, description, apiPath, originalError })
  }
}

export class InternalServerError extends CustomError {
  constructor(apiPath: string, originalError: any) {
    const title = 'Sedang terjadi kendala'
    const description =
      'Jangan khawatir, hal ini bersifat sementara. Silakan perbarui halaman ini beberapa saat lagi.'
    super({ title, description, apiPath, originalError })
  }
}

export class ForbiddenError extends CustomError {
  constructor(apiPath: string, originalError: any) {
    const title = 'Anda tidak memiliki akses untuk halaman ini'
    const description =
      'Gunakan akun lain (belajar.id / madrasah.kemenag.go.id) untuk dapat mengakses halaman ini. Jika masih mengalami kesulitan, silakan hubungi bantuan.'
    super({ title, description, apiPath, originalError })
  }
}

export class NotFoundError extends CustomError {
  constructor(apiPath: string, originalError: any) {
    const title = 'Tidak menemukan yang Anda cari'
    const description =
      'Halaman mungkin dihapus atau terdapat kesalahan pada tautan. Ketuk tombol di bawah untuk lihat konten lainnya.'
    super({ title, description, apiPath, originalError })
  }
}

export class ServiceUnavailableError extends CustomError {
  constructor(apiPath: string, originalError: any) {
    let title = 'Sedang terjadi kendala'
    let description =
      'Jangan khawatir, hal ini bersifat sementara. Silakan perbarui halaman ini beberapa saat lagi.'

    if (apiPath.includes('/teachers-career/v1alpha1')) {
      title = 'Tidak dapat diakses'
      description =
        'Karena adanya pemeliharaan sistem, Bukti Karya baru dapat diakses lagi pada 12 Mei 2022 pukul 10.00 WIB.'
    }

    super({ title, description, apiPath, originalError })
  }
}

export class NetworkError extends CustomError {
  constructor(apiPath: string, originalError: any) {
    const title = 'Tidak Ada Koneksi Internet'
    const description =
      'Pastikan koneksi internet Anda aktif untuk memuat halaman ini.'
    super({ title, description, apiPath, originalError })
  }
}

export class NotStartedError extends CustomError {
  constructor(apiPath: string, originalError: any) {
    const title = 'Belum dimulai'
    const description =
      'Kami sedang mempersiapkan halaman ini. Silakan perbarui halaman ini beberapa saat lagi.'
    super({ title, description, apiPath, originalError })
  }
}
