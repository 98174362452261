import create from 'zustand'
import { persist } from 'zustand/middleware'

import type { State } from 'zustand'

export const META_DATA_STORAGE_KEY = 'meta-data'

export type TMetaDataStore = State & {
  ip?: string
  setIP?: (ip: string) => void
}

const initialState: TMetaDataStore = {
  ip: null,
}

export const useMetaDataStore = create<TMetaDataStore>(
  persist(
    (set) => ({
      ...initialState,
      setIP: (ip: string) => set({ ip }),
    }),
    {
      name: META_DATA_STORAGE_KEY,
      partialize: ({ ip }) => ({
        ip,
      }),
    }
  )
)
