import { useEffect, useState } from 'react'

import { Button } from '@wartek-id/button-v1'
import { Card } from '@wartek-id/card'
import { Text } from '@wartek-id/text-v1'

import DialogFilledBySystem from 'app/KinerjaPage/pages/PelaksanaanV2/components/DialogFilledBySystem'
import { assetUrl } from 'utils/url'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

type CardFilledBySystemProps = {
  isGuru?: boolean
  className?: string
  shouldInitiallyShowPopUp?: boolean
  // ID for local storage key, it used to store the initial state of popup
  storageId: string
}

export default function CardFilledBySystem({
  className,
  isGuru = false,
  shouldInitiallyShowPopUp = false,
  storageId,
}: CardFilledBySystemProps) {
  const [isOpen, setOpen] = useState(false)

  const [storedValue, setValue] = useLocalStorage(
    `popup_filled_by_system_shown_${isGuru ? 'guru' : 'ks'}_${storageId}`,
    false
  )

  const handleClickDismissPopup = () => {
    setOpen(false)
    setValue(true)
  }

  // Initially show popup if necesarry
  useEffect(() => {
    const isShown = storedValue === true
    if (shouldInitiallyShowPopUp && !isShown) {
      setOpen(true)
    }
  }, [shouldInitiallyShowPopUp, storedValue])

  return (
    <>
      <Card className={'p-6 !flex-row space-x-4 items-center ' + className}>
        <img
          src={assetUrl('/illustrations/img-notification-bell.png')}
          alt=""
          width={84}
          height={84}
          className="flex-none"
        />
        <div className="w-full flex-1 space-y-1">
          <Text variant="heading-sm" className="!text-black" as="h2">
            {isGuru
              ? 'Info Penting: Ada penyesuaian pada Pelaksanaan Kinerja Anda'
              : 'Info Penting: Ada penyesuaian pada Penilaian Kinerja guru ini'}
          </Text>
          <Text color="subdued">
            {isGuru
              ? 'Ini terjadi karena Anda baru memperoleh Penugasan JF Guru. Penyesuaian hanya untuk periode ini.'
              : 'Ini terjadi karena guru ini baru memperoleh Penugasan JF Guru. Penyesuaian hanya untuk periode ini.'}
          </Text>
        </div>

        <Button
          color="black"
          size="md"
          aria-label="Cek selengkapnya"
          onClick={() => setOpen(true)}
        >
          {isGuru ? 'Selengkapnya' : 'Cek selengkapnya'}
        </Button>
      </Card>

      <DialogFilledBySystem
        isGuru={isGuru}
        isOpen={isOpen}
        onClose={handleClickDismissPopup}
      />
    </>
  )
}
