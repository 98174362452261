import axios from 'axios'
import { useMutation } from 'react-query'

import { baseUrl } from 'configs/api'
import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'

export function useMutateAPILogout() {
  const url = `${baseUrl}/guru/teachers/v1alpha2/logout`
  const [session] = useLocalStorage(APP_LOGIN_STORAGE_KEY, null)
  const guruToken = session?.guruToken ?? null

  return useMutation(
    () =>
      axios({
        method: 'POST',
        url,
        headers: { Authorization: `Bearer ${guruToken}` },
      }),
    {
      retry: 2,
      retryDelay: 500,
      useErrorBoundary: false,
    }
  )
}
