import type { CommonResponse } from 'types/api'
import type { ResponsePelaksanaanDocumentPreparation } from 'types/kinerja-pelaksanaan'

export const dummyDocumentPreparation: CommonResponse<ResponsePelaksanaanDocumentPreparation> =
  {
    data: {
      state: 'DRAFT',
      detail: {
        behavioralTargets: [
          {
            id: 'test-id-1',
            behavioralTarget:
              'Guru melakukan komunikasi positif untuk membangun suasana kelas yang kondusif',
          },
          {
            id: 'test-id-2',
            behavioralTarget:
              'Guru melakukan strategi pengelompokkan untuk mengaktifkan keterlibatan murid',
          },
          {
            id: 'test-id-3',
            behavioralTarget:
              'Guru membuat dan mengingatkan aturan/kesepakatan kelas',
          },
        ],
        behavioralTargetEffort: 'testing akun cgp asd',
        observationDate: '2024-02-29',
        observationLevel: 'SD',
        observationClass: 'Kelas 2',
        observationSubject: 'Bahasa Inggris',
        observationNote: 'test',
        observationDocuments: [],
      },
    },
    meta: {},
  }
