import type { ResponseGetPenetapanPredikatKinerjaStep } from 'types/kinerja-pelaksanaan'

export const dummyPenetapanPredikatKinerjaStep: ResponseGetPenetapanPredikatKinerjaStep =
  {
    data: {
      submissionStep: 'NOT_CREATED',
      deliveryStatus: 'NOT_SENT',
      state: 'NOT_CREATED',
      updatedAt: '2024-05-14T03:14:55+00:00',
      totalNotCompletedRotasiMutasi: 2,
    },
    meta: {},
  }
