import * as React from 'react'
import { useRouter } from 'next/router'

import { useLocalStorage } from 'utils/hooks/useLocalStorage'

export const REFERRER_KEY = 'referrer'

const useRouterHistory = () => {
  const [, setReferrer] = useLocalStorage(REFERRER_KEY, '')
  const [routes, setRoutes] = React.useState(['', ''])
  const router = useRouter()

  const updateCurrentRoute = React.useCallback(
    (url) => {
      setRoutes([routes[1], url])
      setReferrer(routes[1])
    },
    [routes, setReferrer]
  )

  const onHistoryChange = React.useCallback(
    (url) => {
      if (url !== routes[1]) {
        updateCurrentRoute(url)
      }
    },
    [updateCurrentRoute, routes]
  )

  React.useEffect(() => {
    updateCurrentRoute(router.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    router.events.on('routeChangeStart', onHistoryChange)
    return () => {
      router.events.off('routeChangeStart', onHistoryChange)
    }
  }, [onHistoryChange, router.events, routes])
}

export default useRouterHistory
