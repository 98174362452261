import { parse } from 'date-fns'

import { formatDate, months as monthList } from 'utils/datetime'

/**
 * An array representing the month numbers.
 * Each element is a tuple containing the start and end month numbers.
 */
const monthNumbers: Array<[number, number]> = [
  [1, 6],
  [7, 12],
]

/**
 * Array of month names.
 * Each element in the array is a tuple containing the start and end month names.
 * The month names are retrieved from the `monthList` array using the `monthNumbers` array.
 *
 * @type {Array<[string, string]>}
 */
export const monthNames: Array<[string, string]> = monthNumbers.map(
  ([start, end]) => [monthList[start - 1], monthList[end - 1]]
)

/**
 * Returns the first day of a given month.
 * @param monthNumber - The month number.
 * @param year - The year.
 * @returns The first day of the month.
 */
const getFirstDayOfMonth = (month: number, year: number): number => {
  return new Date(year, month - 1, 1).getDate()
}

/**
 * Returns the last day of a given month.
 * @param monthNumber - The month number.
 * @param year - The year.
 * @returns The last day of the month.
 */
const getLastDayOfMonth = (month: number, year: number): number => {
  return new Date(year, month, 0).getDate()
}

/**
 * Returns the start and end month names or the start and end dates of the month period.
 * @param period - The period number.
 * @param year - The year.
 * @param withDateYear - Optional. Specifies whether to include the dates in the result. Default is false.
 * @returns An array containing the start and end month names or the start and end dates of the month period.
 */
export const monthDates = (
  period: number,
  year: number = new Date().getFullYear(),
  withDate: boolean = false,
  withYear: boolean = false
): [string, string] => {
  const [startNumber, endNumber] = monthNumbers[period - 1 || 0]
  const [start, end] = monthNames[period - 1 || 0]

  if (!withDate && !withYear) return [start, end]

  const dates: [string, string] = [
    [...(withDate ? [getFirstDayOfMonth(startNumber, year)] : []), start].join(
      ' '
    ),
    [
      ...(withDate ? [getLastDayOfMonth(endNumber, year)] : []),
      end,
      ...(withYear ? [year] : []),
    ].join(' '),
  ]

  return dates
}

type FormatPeriod = {
  withDate?: boolean
  withYear?: boolean
  separator?: string
}

/**
 * Formats the period between a start date and an end date.
 * @param startDate - The start date of the period in the format 'yyyy-MM-dd'.
 * @param endDate - The end date of the period in the format 'yyyy-MM-dd'.
 * @param options - Additional formatting options.
 * @param options.withDate - Specifies whether to include the date in the formatted period. Default is false.
 * @param options.separator - The separator to use between the start and end dates. Default is ' - '.
 * @returns The formatted period string.
 */
export const formatPeriod = (
  startDate: string,
  endDate: string,
  opts?: FormatPeriod
) => {
  // Destructure the options object and set default values for withDate, withYear, and separator
  const { withDate = false, withYear = true, separator = ' - ' } = opts || {}

  // Parse the start and end dates using the 'yyyy-MM-dd' format
  const parseStartDate = parse(startDate, 'yyyy-MM-dd', new Date())
  const parseEndDate = parse(endDate, 'yyyy-MM-dd', new Date())

  // Define the date format based on the options
  const dateFullFormats = [
    ...(withDate ? ['d'] : []),
    'LLLL',
    ...(withYear ? ['yyyy'] : []),
  ]

  // Join the date format array to create the format string
  const dateFullFormat = dateFullFormats?.join(' ')

  // Define the date format without the year based on the options
  const dateFormats = [
    ...(withDate ? ['d'] : []),
    ...(parseStartDate.getMonth() === parseEndDate.getMonth() ? [] : ['LLLL']),
  ]

  // Join the date format array to create the format string
  const dateFormat = dateFormats.join(' ')

  // Format the start period based on the year
  const formattedStartPeriod =
    parseStartDate.getFullYear() == parseEndDate.getFullYear()
      ? formatDate(parseStartDate, dateFormat)
      : formatDate(parseStartDate, dateFullFormat)

  // Format the end period
  const formattedEndPeriod = formatDate(parseEndDate, dateFullFormat)

  return `${formattedStartPeriod}${separator}${formattedEndPeriod}`
}
