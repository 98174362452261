import React from 'react'

type ParseFunction = (
  text: string,
  reviver?: (this: any, key: string, value: any) => any
) => any

type StringifyFunction = (
  value: any,
  replacer?: (this: any, key: string, value: any) => any,
  space?: string | number
) => string

export function useLocalStorage(
  key: string,
  initialValue,
  parseFn?: ParseFunction,
  stringifyFn?: StringifyFunction
) {
  const parse: ParseFunction = parseFn || JSON.parse
  const stringify: StringifyFunction = stringifyFn || JSON.stringify
  const getValue = () => {
    try {
      if (typeof window !== 'undefined') {
        const item = window.localStorage.getItem(key)
        return item ? parse(item) : initialValue
      }
      return initialValue
    } catch (error) {
      // eslint-disable-next-line
      console.log('error in local storage key:', key)
      // eslint-disable-next-line
      console.error(error)
      return initialValue
    }
  }

  const [storedValue, setStoredValue] = React.useState(getValue())

  const setValue = (value) => {
    try {
      setStoredValue(value)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, stringify(value))
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('error in local storage key:', key)
      // eslint-disable-next-line
      console.error(error)
    }
  }

  const removeValue = () => {
    try {
      setStoredValue(null)
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key)
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('error in local storage key:', key)
      // eslint-disable-next-line
      console.error(error)
    }
  }

  return [storedValue, setValue, removeValue, getValue]
}
