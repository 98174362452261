import React from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'

import * as metaPixel from 'utils/metaPixel'

export const MetaPixel = () => {
  const router = useRouter()
  const isExcludeFromPageDocument =
    process.env.NEXT_PUBLIC_ENV === 'test' && router.pathname === '/dokumen'

  React.useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_META_PIXEL_ENABLED !== 'true' ||
      isExcludeFromPageDocument
    ) {
      return
    }

    // This pageview only triggers the first time (it's important for Pixel to have real information)
    metaPixel.pageView()

    const handleRouteChange = () => {
      metaPixel.pageView()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, isExcludeFromPageDocument])

  if (
    process.env.NEXT_PUBLIC_META_PIXEL_ENABLED !== 'true' ||
    typeof fbq !== 'undefined'
  ) {
    return null
  }

  if (isExcludeFromPageDocument) {
    return null
  }

  return (
    <Script
      id="meta-pixel"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_META_PIXEL_ID}');
          fbq('set', 'autoConfig', 'false', '${process.env.NEXT_PUBLIC_META_PIXEL_ID}')
        `,
      }}
    />
  )
}
