const ENV =
  process.env.NEXT_PUBLIC_ENV === 'production' ? 'production' : 'staging'

const DEFAULT_OPTIONS = {
  env: ENV,
}

export const pageView = (): void => {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'PageView')
  }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options: any = {}): void => {
  if (typeof fbq !== 'undefined') {
    fbq('track', name, {
      ...DEFAULT_OPTIONS,
      ...options,
    })
  }
}
