import React from 'react'
import { setUser } from '@sentry/nextjs'

import { LayoutWithHeader as Layout } from 'components/Layout'
import { EmptyState, EmptyStateAction } from 'components/EmptyState'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import AndroidHandler from 'utils/androidHandler'
import { timeout } from 'utils/process'
import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'

import type { AuthContextType, UseAuthProvider } from './types'
import type { Session } from 'types/auth'
import type { ApiStatus } from 'types/api'

const AuthContextDefaultValue: AuthContextType = {
  session: null,
  login: () => {},
  loginScriptIsLoaded: true,
  logout: () => {},
  validateTokenError: null,
  lastUsedEmailDomain: null,
  error: null,
}

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextDefaultValue
)
AuthContext.displayName = 'AuthContext'

export function useAuthProvider(immediate: boolean = true): UseAuthProvider {
  const { run, session, status, error, resetSession } = useValidateToken()

  React.useEffect(() => {
    resetSession()
    if (immediate) {
      run()
    }
    // eslint-disable-next-line
  }, [])

  return {
    session,
    login: run,
    loginScriptIsLoaded: true,
    logout: resetSession,
    status,
    error: null,
    validateTokenError: error,
    resetSession,
    lastUsedEmailDomain: null,
  }
}

export function AuthProvider(props) {
  const androidHandler = AndroidHandler()
  const {
    session,
    login,
    loginScriptIsLoaded,
    logout,
    status,
    error,
    validateTokenError,
    lastUsedEmailDomain,
  } = useAuthProvider()

  const value = React.useMemo(
    () => ({
      session,
      login,
      loginScriptIsLoaded,
      logout,
      validateTokenError,
      lastUsedEmailDomain,
    }),
    [
      session,
      login,
      loginScriptIsLoaded,
      logout,
      validateTokenError,
      lastUsedEmailDomain,
    ]
  )

  if (['idle', 'loading'].includes(status)) {
    return null
  }

  if (status === 'success') {
    return <AuthContext.Provider value={value} {...props} />
  }

  if (error || status === 'error') {
    return (
      <Layout title="" onClickBack={() => androidHandler.backToApp()}>
        <EmptyState
          title="Gagal Login"
          description={`Ada kesalahan pada sistem.\n${
            error || validateTokenError
          }`}
          className="h-screen"
          withHeader={false}
        >
          <EmptyStateAction onClick={login}>Coba Lagi</EmptyStateAction>
        </EmptyState>
      </Layout>
    )
  }

  throw new Error('Unhandled states of AuthProvider component')
}

function useValidateToken() {
  const androidHandler = AndroidHandler()
  const [storedSession, setSession, removeSession] = useLocalStorage(
    APP_LOGIN_STORAGE_KEY,
    null
  )
  const [status, setStatus] = React.useState<ApiStatus>('idle')
  const [error, setError] = React.useState(null)

  const run = async () => {
    setStatus('loading')

    const validateToken = androidHandler.guruTokenEnabled
      ? validateGuruToken
      : validateAccessToken

    try {
      const session: Session = await validateToken()
      if (!!session) {
        setSession(session)
      }

      const userId = storedSession?.user?.id ?? session?.user?.id ?? null
      if (userId) {
        setUser({ id: userId })
      }

      setStatus('success')
    } catch (err) {
      setError(err)
      setStatus('error')
    }
  }

  const resetSession = () => {
    setUser(null)
    removeSession()
    setStatus('idle')
  }

  return {
    run,
    session: storedSession,
    status,
    error,
    resetSession,
  }
}

export async function validateGuruToken(): Promise<Session | null> {
  const androidHandler = AndroidHandler()
  let authData = await androidHandler.getAuthData()
  const isLogin = !!authData?.user?.id

  if (isLogin) {
    if (androidHandler.getVersion() < 10800) {
      authData = await androidHandler.getAuthData(true)
    }

    if (!authData) {
      androidHandler?.backToApp?.()
    }

    return {
      accessToken: '',
      tokenType: 'Bearer',
      expiryToken: authData.expiredAt,
      refreshToken: 'dummy-refresh-token',
      email: authData.user.email,
      guruToken: authData.guruToken,
      expiredAt: authData.expiredAt,
      user: authData.user,
    }
  }
  return null
}

export async function validateAccessToken(): Promise<Session | null> {
  const androidHandler = AndroidHandler()
  const isLogin = !!androidHandler.getUserId()

  if (isLogin) {
    androidHandler.refreshToken()

    await timeout(1000)
    const accessToken = androidHandler.requestAccessToken()
    const expiry = new Date(new Date().getTime() + 3600 * 1000).toISOString()
    const user = JSON.parse(androidHandler.getUser())

    return {
      accessToken,
      tokenType: 'Bearer',
      expiryToken: expiry,
      refreshToken: 'dummy-refresh-token',
      email: androidHandler.getEmail(),
      guruToken: '',
      expiredAt: expiry,
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
        picture: user.photoUrl,
        groups: user.groups ?? [],
      },
    }
  }
  return null
}
