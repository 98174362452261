import type { GuruTokenSession } from 'types/auth'

interface WindowAndroidHandler {
  getAuthToken: () => string | null
  getServerAuthCode: () => string | null
  getEmail: () => string | null
  requestAccessToken: () => string | null
  getUser: () => string | null
  getUserId: () => string | null
  refreshToken: () => string | null
  backToApp: () => void | null
  // Note: above properties will be deprecated and will be replace by this function
  shareToolkitUrl: (textToCopy: string) => void | null
  getAuthData: (refresh?: boolean) => Promise<GuruTokenSession> | null
  requestLogin: () => void | null
  copyText: (textToCopy: string) => void | null
  downloadCollection: (params: any) => void | null
}

interface AndroidHandler extends WindowAndroidHandler {
  enabled: boolean
  guruTokenEnabled: boolean
  getVersion: () => number
  backToHomepage: (callback?: () => void) => void
}

export function createAndroidAuthDataCallback() {
  if (typeof window !== 'undefined' && window.AndroidHandler) {
    window.resolveGetAuthData = null
    window.rejectGetAuthData = null
    window.getAuthDataSuccess = (response: string) => {
      const { data } = JSON.parse(response) as { data: GuruTokenSession }
      if (window.resolveGetAuthData) {
        window.resolveGetAuthData(data)
        window.resolveGetAuthData = null
      }
      // eslint-disable-next-line
      console.log('auth-data', JSON.stringify(data))
    }
    window.getAuthDataError = (error: string) => {
      if (window.rejectGetAuthData) {
        window.rejectGetAuthData(error)
        window.rejectGetAuthData = null
      }
      // eslint-disable-next-line
      console.log('auth-data-error', error)
    }
  }
}

function getVersion(): number {
  const originalUserAgent = window.navigator.userAgent
  const prefix = 'androidmobile PMM/'
  let version = 0
  if (originalUserAgent.includes(prefix)) {
    version = Number(originalUserAgent.split(prefix).join('').split(' ')[0])
  }
  return version
}

function backToHomepage(fallbackFn: () => void = () => {}): void {
  if (typeof window !== 'undefined' && window.AndroidHandler) {
    window.location.href =
      process.env.NEXT_PUBLIC_BASE_URL || 'https://guru.kemdikbud.go.id'
  } else {
    fallbackFn()
  }
}

/**
 * @typedef IDownloadCollectionParams
 * @prop {string} path Destination download folder
 * @prop {string} url URL of the File
 * @prop {string} toast Toast Message
 *
 */
interface IDownloadCollectionParams {
  path: string
  url: string
  toast: string
}

/**
 * Call JS Bridge to Download File URL using Native Android Webview downloader.
 * @example
 * ```
 * downloadCollection({
 *   path: "Kurikulum Merdeka/",
 *   url: "https://uploads.staging.belajar.id/file.pdf",
 *   toast: "Sukses Mengunduh!"
 * });
 * ```
 */
function downloadCollection(params: IDownloadCollectionParams): void {
  if (
    typeof window !== 'undefined' &&
    window.AndroidHandler &&
    typeof window.AndroidHandler?.downloadCollection !== 'function'
  ) {
    throw Error('AndroidHandler.downloadCollection not available')
  }

  window.AndroidHandler.downloadCollection(JSON.stringify(params))
}

export default function androidHandler(): AndroidHandler {
  if (typeof window !== 'undefined' && window.AndroidHandler) {
    return {
      enabled: true,
      guruTokenEnabled: !!window.AndroidHandler.getAuthData,
      getVersion,
      backToHomepage,
      downloadCollection,
      getAuthToken: () => window.AndroidHandler.getAuthToken(),
      getServerAuthCode: () => window.AndroidHandler.getServerAuthCode(),
      requestAccessToken: () => window.AndroidHandler.requestAccessToken(),
      getEmail: () => window.AndroidHandler.getEmail(),
      getUser: () => window.AndroidHandler.getUser(),
      getUserId: () => window.AndroidHandler?.getUserId?.() ?? null,
      refreshToken: () => window.AndroidHandler.refreshToken(),
      backToApp: () => window.AndroidHandler.backToApp(),
      shareToolkitUrl: (textToCopy: string) =>
        window.AndroidHandler.shareToolkitUrl(textToCopy),
      getAuthData: (refresh: boolean = false) => {
        window.AndroidHandler.getAuthData(refresh)
        return new Promise((resolve, reject) => {
          window.resolveGetAuthData = resolve
          window.rejectGetAuthData = reject
        })
      },
      requestLogin: () => window.AndroidHandler.requestLogin(),
      copyText: (textToCopy: string) =>
        window.AndroidHandler.copyText(textToCopy),
    }
  }

  return {
    enabled: false,
    guruTokenEnabled: false,
    getVersion,
    backToHomepage,
    downloadCollection,
    getAuthToken: null,
    getServerAuthCode: null,
    requestAccessToken: null,
    getEmail: null,
    getUser: null,
    getUserId: null,
    refreshToken: null,
    backToApp: null,
    shareToolkitUrl: null,
    getAuthData: null,
    requestLogin: null,
    copyText: null,
  }
}
