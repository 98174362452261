import { LESS_THAN_OR_EQUAL_URI, PLUS_MINUS_URI } from 'utils/symbol'

import type { TLevel, TFase } from 'types/classAndPhases'

export const CognitiveTypeOrder = {
  Knowing: 0,
  Applying: 1,
  Reasoning: 2,
  Retrieve: 0,
  Interpret: 1,
  Evaluate: 2,
}

export const toolkitDomainMap: Record<string, string[]> = {
  Geometri: [
    'Dimensi Tiga',
    'Teori Bidang Dimensi Tiga',
    'Dimensi',
    'Teori Dimensi Tiga',
    'Penerapan Teori Dimensi',
    'Penerapan Teori Bangun Ruang',
    'Teori Bangun Ruang',
    'Bangun Ruang',
    'Teori Dimensi',
    'Dimensi dan Contoh',
    'Bangun Ruang dan Contoh',
  ],
  'Data dan Ketidakpastian': [
    'Teori Statistika',
    'Teori Peluang',
    'Teori Statistika dan Penerapan',
    'Teori Peluang dan Contoh',
    'Statistika',
    'Teori Statistika dan Contoh',
    'Penerapan Teori Peluang dan Contoh',
    'Peluang',
    'Teori Peluang dan Penerapan',
    'Statistika dalam Kehidupan',
    'Penerapan Teori Statistika dan Penerapan',
    'Peluang Matematika',
  ],
  Aljabar: [
    'Limit',
    'Limit Fungsi dan Contoh',
    'Teori Limit',
    'Penerapan Teori Limit Fungsi',
    'Limit Fungsi',
    'Teori Limit Fungsi',
  ],
}

export interface ThemeOption {
  label: string
  level: TLevel[]
}

export const THEME_OPTIONS: ThemeOption[] = [
  {
    label: 'Gaya Hidup Berkelanjutan',
    level: ['SD', 'SMP', 'SMA', 'SMK'],
  },
  {
    label: 'Kearifan Lokal',
    level: ['SD', 'SMP', 'SMA', 'SMK'],
  },
  {
    label: 'Bhinneka Tunggal Ika',
    level: ['SD', 'SMP', 'SMA', 'SMK'],
  },
  {
    label: 'Bangunlah Jiwa dan Raganya',
    level: ['SD', 'SMP', 'SMA', 'SMK'],
  },
  {
    label: 'Suara Demokrasi',
    level: ['SMP', 'SMA', 'SMK'],
  },
  {
    label: 'Rekayasa dan Teknologi',
    level: ['SD', 'SMP', 'SMA', 'SMK'],
  },
  {
    label: 'Kewirausahaan',
    level: ['SD', 'SMP', 'SMA'],
  },
  {
    label: 'Kebekerjaan',
    level: ['SMK'],
  },
  {
    label: 'Aku Sayang Bumi',
    level: ['PAUD'],
  },
  {
    label: 'Aku Cinta Indonesia',
    level: ['PAUD'],
  },
  {
    label: 'Kita Semua Bersaudara',
    level: ['PAUD'],
  },
  {
    label: 'Imajinasi dan Kreativitasku',
    level: ['PAUD'],
  },
]

export type TSLBPhaseDetail = { classes: string[]; mentalAge?: string }

export const SLB_DETAIL: Record<TFase, TSLBPhaseDetail> = {
  Fondasi: {
    classes: ['PAUDLB'],
  },
  A: {
    classes: ['Kelas 1', 'Kelas 2'],
    mentalAge: `${decodeURI(LESS_THAN_OR_EQUAL_URI)} 7`,
  },
  B: {
    classes: ['Kelas 3', 'Kelas 4'],
    mentalAge: `${decodeURI(PLUS_MINUS_URI)} 8`,
  },
  C: {
    classes: ['Kelas 5', 'Kelas 6'],
    mentalAge: `${decodeURI(PLUS_MINUS_URI)} 8`,
  },
  D: {
    classes: ['Kelas 7', 'Kelas 9'],
    mentalAge: `${decodeURI(PLUS_MINUS_URI)} 9`,
  },
  E: {
    classes: ['Kelas 10'],
    mentalAge: `${decodeURI(PLUS_MINUS_URI)} 10`,
  },
  F: {
    classes: ['Kelas 11', 'Kelas 12'],
    mentalAge: `${decodeURI(PLUS_MINUS_URI)} 10`,
  },
}

export const GENERAL_PHASES: TFase[] = ['Fondasi', 'A', 'B', 'C', 'D', 'E', 'F']

export const ASSESSMENT_LEARNING_BUCKET_NAME =
  process.env.NODE_ENV === 'production'
    ? 'toolkit-public-production'
    : 'toolkit-public-staging'

export const EVENT_NAME_ASSESSMENT_VIEW_HOME_V1 =
  'guru_assessment_assessment_view_home_v1'

export const EVENT_NAME_ASSESSMENT_QUESTION_PACKET_UPDATED =
  'guru_assessment_question_packet_updated'

export const EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_HOME_V1 =
  'guru_assessment_assessment_subject_view_home_v1'

export const EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_HOME_V2 =
  'guru_assessment_assessment_subject_view_home_v2'

export const EVENT_NAME_ASSESSMENT_SUBJECT_CLICK_SEARCH_V1 =
  'assessment_subject_click_search_v1'

export const EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_SEARCH_RESULTS_V1 =
  'assessment_subject_view_search_results_v1'

export const EVENT_NAME_ASSESSMENT_SUBJECT_CLICK_CARD_V1 =
  'assessment_subject_click_card_v1'

export const EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_DETAIL_V1 =
  'assessment_subject_view_detail_v1'

export const EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_MATERIAL_V1 =
  'assessment_subject_view_material_v1'

export const EVENT_NAME_ASSESSMENT_SUBJECT_DOWNLOAD_V1 =
  'assessment_subject_download_v1'

export const EVENT_NAME_ASSESSMENT_SUBJECT_SHARE_V1 =
  'assessment_subject_share_v1'

export const EVENT_NAME_ASSESSMENT_LITNUM_CLICK_CARD_V1 =
  'assessment_litnum_click_card_v1'

export const EVENT_NAME_ASSESSMENT_LITNUM_DISTRIBUTE_V1 =
  'assessment_litnum_distribute_v1'

export const EVENT_NAME_ASSESSMENT_LITNUM_SHARE_V1 =
  'assessment_litnum_share_v1'

export const EVENT_NAME_ASSESSMENT_LITNUM_VIEW_HOME_V1 =
  'assessment_litnum_view_home_v1'

export const EVENT_NAME_ASSESSMENT_LITNUM_VIEW_HOME_V2 =
  'assessment_litnum_view_home_v2'

export const EVENT_NAME_ASSESSMENT_LITNUM_VIEW_DETAIL_V1 =
  'assessment_litnum_view_detail_v1'

export const enabledAssessmentTrackers = [
  EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_HOME_V1,
  EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_HOME_V2,
  EVENT_NAME_ASSESSMENT_VIEW_HOME_V1,
  EVENT_NAME_ASSESSMENT_QUESTION_PACKET_UPDATED,
  EVENT_NAME_ASSESSMENT_SUBJECT_CLICK_SEARCH_V1,
  EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_SEARCH_RESULTS_V1,
  EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_MATERIAL_V1,
  EVENT_NAME_ASSESSMENT_SUBJECT_CLICK_CARD_V1,
  EVENT_NAME_ASSESSMENT_SUBJECT_VIEW_DETAIL_V1,
  EVENT_NAME_ASSESSMENT_SUBJECT_DOWNLOAD_V1,
  EVENT_NAME_ASSESSMENT_SUBJECT_SHARE_V1,
  EVENT_NAME_ASSESSMENT_LITNUM_CLICK_CARD_V1,
  EVENT_NAME_ASSESSMENT_LITNUM_DISTRIBUTE_V1,
  EVENT_NAME_ASSESSMENT_LITNUM_SHARE_V1,
  EVENT_NAME_ASSESSMENT_LITNUM_VIEW_HOME_V1,
  EVENT_NAME_ASSESSMENT_LITNUM_VIEW_HOME_V2,
  EVENT_NAME_ASSESSMENT_LITNUM_VIEW_DETAIL_V1,
]
