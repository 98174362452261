import { createContext, useContext } from 'react'

export type UseDemoModeOptions<T = {}> = T & { isDemoMode?: boolean }

export const DemoModeContext = createContext({
  isDemoMode: false,
})

export default function useDemoContext() {
  return useContext(DemoModeContext)
}
