import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ToastProvider } from '@wartek-id/toast'
import { ToastProvider as ToastProviderv2 } from '@wartek-id/toast-v2'
import { historyUtils } from '@wartek-id/fe-toolbox'

import ToggleFetcher from 'components/ToggleFetcher'
import GlobalDataFetcher from 'components/GlobalDafaFetcher'
import { AuthProvider, ProtectedRoutes } from 'utils/auth'
import { createAndroidAuthDataCallback } from 'utils/androidHandler'
import { Layout as BaseLayout } from 'components/Layout/Layout'
import ErrorBoundary from 'components/ErrorBoundary'
import useRouterHistory from 'utils/hooks/useRouterHistory'
import useAppDeviceId from 'utils/hooks/useAppDeviceId'
import useTechDebtStoreUserId from 'utils/hooks/useTechDebtStoreUserId'
import { GoogleAnalytics } from 'components/GoogleAnalytics'
import { MetaPixel } from 'components/MetaPixel'
import { MetaTags } from 'components/MetaTags'
import { EXCLUDE_INITIAL_API_FETCH_PATHS } from 'configs/routes'
import '@wartek-id/design-tokens/build/guru-mobile/css/color.css'
import '@wartek-id/design-tokens/build/guru-mobile/css/font.css'
import '@wartek-id/design-tokens/build/guru-mobile/css/size.css'
import '@wartek-id/design-tokens/build/guru-mobile/css/elevation.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'styles/global.css'
import { UserGroupsProvider } from 'utils/providers/useUserGroupsContext'
import { ExperimentsProvider } from 'utils/providers/useExperimentsContext'

const STATIC_PAGES = ['/tns', '/policy', '/pdf-viewer']

createAndroidAuthDataCallback()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  const mockServer = require('configs/mocks/server').default
  mockServer({ environment: process.env.NODE_ENV })
}

const App = ({ Component, pageProps, router }) => {
  useRouterHistory()
  const Layout = Component.Layout ?? BaseLayout

  return (
    <Layout {...Component.layoutOpts} pageProps={pageProps}>
      <GoogleAnalytics />
      <MetaPixel />
      <ExperimentsProvider>
        <Component {...pageProps} key={router.route} />
      </ExperimentsProvider>
    </Layout>
  )
}

const AppProvider = ({ Component, pageProps, router }) => {
  const isInitialAPIFetchEnabled = !EXCLUDE_INITIAL_API_FETCH_PATHS.includes(
    router.pathname
  )
  useTechDebtStoreUserId()
  useAppDeviceId(pageProps?.appDeviceID)

  React.useEffect(() => {
    historyUtils.storeAppInitialPath()
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
  }, [])

  return STATIC_PAGES.includes(router.pathname) ? (
    <ToastProvider autoDismiss placement="top-center">
      <QueryClientProvider client={queryClient}>
        <App Component={Component} pageProps={pageProps} router={router} />
      </QueryClientProvider>
    </ToastProvider>
  ) : (
    <ToastProviderv2>
      <ToastProvider autoDismiss placement="top-center">
        <QueryClientProvider client={queryClient}>
          <MetaTags
            metaTags={pageProps.metaTags}
            pageTitle={pageProps.pageTitle}
            pageDescription={pageProps.pageDescription}
          />
          <AuthProvider>
            <ErrorBoundary resetKeys={[router.asPath]}>
              <ToggleFetcher isEnabled={isInitialAPIFetchEnabled}>
                <ProtectedRoutes router={router}>
                  <>
                    {isInitialAPIFetchEnabled && <GlobalDataFetcher />}
                    <UserGroupsProvider>
                      <App
                        Component={Component}
                        pageProps={pageProps}
                        router={router}
                      />
                    </UserGroupsProvider>
                  </>
                </ProtectedRoutes>
              </ToggleFetcher>
            </ErrorBoundary>
          </AuthProvider>
        </QueryClientProvider>
      </ToastProvider>
    </ToastProviderv2>
  )
}

export default AppProvider
