import { useRouter } from 'next/router'
import React from 'react'

import {
  useAPIGetFilters,
  useAPIGetToolkitFilters,
} from 'api/filters/useAPIFilters'
import { useFiltersStore } from 'stores/filters'
import { getUserIP } from 'api/metadata'
import { useMetaDataStore } from 'stores/metadata'
import { useTogglesStore } from 'stores/toggles'
import { useToolkitFilterStore } from 'stores/toolkitFilters'
import { useAuth } from 'utils/auth'

const GlobalDataFetcher = ({ children }: React.HTMLProps<HTMLDivElement>) => {
  const toggles = useTogglesStore((state) => state?.toggles)
  const router = useRouter()
  const setGetToolkitFiltersLoading = useToolkitFilterStore(
    (state) => state.setIsLoading
  )
  const { session } = useAuth()
  const { isLoading: isGetToolkitFiltersLoading } = useAPIGetToolkitFilters({
    isFromBucket: toggles?.['use-toolkit-filters-from-bucket'],
    enabled: !!session,
  })

  const setGetFiltersLoading = useFiltersStore((state) => state.setIsLoading)
  const setIP = useMetaDataStore((state) => state.setIP)
  const { isLoading: isGetFiltersLoading } = useAPIGetFilters({
    enabled: router?.pathname?.includes('/assessment'),
  })

  const getUserMetaData = async () => {
    try {
      const { data: IPData } = await getUserIP()
      setIP(IPData?.ip)
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    getUserMetaData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setGetFiltersLoading(isGetFiltersLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetFiltersLoading])

  React.useEffect(() => {
    setGetToolkitFiltersLoading(isGetToolkitFiltersLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetToolkitFiltersLoading])
  return <>{children}</>
}

export default GlobalDataFetcher
