import create from 'zustand'
import { persist } from 'zustand/middleware'

import type { TAssessmentEventName } from 'types/assessment'
import type { State } from 'zustand'

export const ASSESSMENT_TRACKER_STORAGE_KEY = 'assessment-tracker'

export type TAssessmentTrackerStore = State & {
  eventTrigger?: TAssessmentEventName
  eventTriggerId?: string
  setEventTrigger?: (eventTrigger: TAssessmentEventName) => void
  setEventTriggerId?: (eventTriggerId: string) => void
}

const initialState: TAssessmentTrackerStore = {
  eventTrigger: null,
  eventTriggerId: null,
}

export const useAssessmentTrackerStore = create<TAssessmentTrackerStore>(
  persist(
    (set) => ({
      ...initialState,
      setEventTrigger: (eventTrigger: TAssessmentEventName) =>
        set({ eventTrigger }),
      setEventTriggerId: (eventTriggerId: string) => set({ eventTriggerId }),
    }),
    {
      name: ASSESSMENT_TRACKER_STORAGE_KEY,
      partialize: ({ eventTrigger, eventTriggerId }) => ({
        eventTrigger,
        eventTriggerId,
      }),
    }
  )
)
