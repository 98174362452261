import { dummyListGuruStates } from './listGuruStates'

import type {
  PredikatKinerjaItem,
  ResponseGetPredikatKinerjaList,
} from 'types/kinerja-pelaksanaan'

const dummyPredikatKinerjaItems: Omit<PredikatKinerjaItem, 'name'>[] = [
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'MEET_EXPECTATION',
      score: 72,
    },
    workBehavior: {
      result: 'EXCEED_EXPECTATION',
      score: 82,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'NEED_IMPROVEMENT',
  },
  {
    departmentLevel: 'Ahli Pertama',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'BELOW_EXPECTATION',
      score: 48,
    },
    workBehavior: {
      result: 'EXCEED_EXPECTATION',
      score: 88,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'GOOD',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'MEET_EXPECTATION',
      score: 78,
    },
    workBehavior: {
      result: 'BELOW_EXPECTATION',
      score: 40,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'GOOD',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'BELOW_EXPECTATION',
      score: 40,
    },
    workBehavior: {
      result: 'MEET_EXPECTATION',
      score: 79,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'NEED_IMPROVEMENT',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'EXCEED_EXPECTATION',
      score: 85,
    },
    workBehavior: {
      result: 'EXCEED_EXPECTATION',
      score: 86,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'GOOD',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'MEET_EXPECTATION',
      score: 64,
    },
    workBehavior: {
      result: 'EXCEED_EXPECTATION',
      score: 94,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'GOOD',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'EXCEED_EXPECTATION',
      score: 88,
    },
    workBehavior: {
      result: 'MEET_EXPECTATION',
      score: 76,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'GOOD',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'MEET_EXPECTATION',
      score: 74,
    },
    workBehavior: {
      result: 'EXCEED_EXPECTATION',
      score: 96,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'GOOD',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'EXCEED_EXPECTATION',
      score: 90,
    },
    workBehavior: {
      result: 'BELOW_EXPECTATION',
      score: 40,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'GOOD',
  },
  {
    departmentLevel: 'Ahli Muda',
    estimationCreditScore: 0,
    ptkId: 'dummy-ptk-id-1',
    performancePractice: {
      result: 'BELOW_EXPECTATION',
      score: 48,
    },
    workBehavior: {
      result: 'MEET_EXPECTATION',
      score: 70,
    },
    pointCompetencyImprovement: {
      target: 32,
      realization: 32,
    },
    predicateRecommendation: 'VERY_GOOD',
  },
]

export const dummyPredikatKinerjaList: ResponseGetPredikatKinerjaList = {
  data: {
    completedUser: dummyPredikatKinerjaItems.map((item, index) => ({
      ...item,
      name: dummyListGuruStates.data[index].name,
      ableSubmit: true,
    })),
    notCompletedUser: [],
    predicateOrganization: {
      required: dummyListGuruStates.data.length - 1,
      requiredPredicate: 'GOOD',
      deliveryStatus: 'SENT',
      total: 2,
      value: 'BAD',
    },
  },
  meta: {},
}
