import { Button } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text-v1'
import { Dialog, DialogOverlay, DialogContent } from '@wartek-id/dialog-v1'
import { Icon } from '@wartek-id/icon-v2'
import type { DialogProps } from '@wartek-id/dialog-v1'

import usePeriodContext from 'app/KinerjaShared/hooks/usePeriodContext'
import CardPeriod from 'app/KinerjaShared/components/CardPeriod'

type DialogFilledBySystemProps = Omit<DialogProps, 'size' | 'children'> & {
  isGuru?: boolean
}
const MAX_PERIOD = 2

export default function DialogFilledBySystem({
  isGuru,
  ...dialogProps
}: DialogFilledBySystemProps) {
  const { periodYear: currentPeriodYear, periodNo: currentPeriodNumber } =
    usePeriodContext()
  const nextPeriodNumber =
    currentPeriodNumber === MAX_PERIOD ? 1 : currentPeriodNumber + 1
  const nextPeriodYear =
    currentPeriodNumber === MAX_PERIOD
      ? currentPeriodYear + 1
      : currentPeriodYear

  const informations = isGuru
    ? [
        'Praktik Kinerja telah diselesaikan secara otomatis oleh sistem.',
        'Pengembangan Kompetensi dan Tugas Tambahan bersifat opsional. Namun, Anda dapat mulai coba laksanakan di periode ini.',
        'Perilaku Kerja tetap dilaksanakan.',
      ]
    : [
        'Praktik Kinerja telah dinilai secara otomatis oleh sistem.',
        'Pengembangan Kompetensi dan Tugas Tambahan dikumpulkan secara opsional oleh guru. Anda tetap dapat periksa di periode ini jika ada yang dikumpulkan.',
        'Perilaku Kerja tetap perlu dinilai.',
      ]

  return (
    <Dialog
      {...dialogProps}
      size="sm"
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <DialogOverlay />
      <DialogContent
        aria-labelledby="card-penyesuaian-pelaksaanaan-dialog-title"
        className="overflow-hidden"
      >
        <header className="bg-surface-subdued space-y-4 p-6">
          <div className="rounded-full py-2 px-4 bg-orange-30 inline-block ">
            <Text variant="heading-md">Info Penting</Text>
          </div>
          <Text
            id="card-penyesuaian-pelaksaanaan-dialog-title"
            as="h2"
            variant="heading-md"
          >
            {isGuru
              ? 'Anda baru memperoleh Penugasan JF Guru. Maka di periode ini '
              : 'Guru ini baru memperoleh Penugasan JF Guru. Maka periode ini '}
            (
            <CardPeriod
              period={currentPeriodNumber}
              year={currentPeriodYear}
              separator="-"
            />
            ):
          </Text>
        </header>
        <div className="p-6 space-y-6">
          <ol className="mb-2 space-y-4">
            {informations.map((info, i) => {
              return (
                <li
                  key={i}
                  className="flex space-x-4 p-4 border border-subdued rounded"
                >
                  <div
                    className="w-6 h-6 rounded-full bg-gray-80 flex-none flex items-center justify-center"
                    role="presentation"
                  >
                    <Text variant="body-sm" color="inverse">
                      {i + 1}
                    </Text>
                  </div>
                  <Text as="h3" variant="action">
                    {info}
                  </Text>
                </li>
              )
            })}
          </ol>

          <aside className="py-4 px-3 rounded bg-orange-10 space-y-3">
            <div className="flex space-x-2 items-center">
              <Icon
                fontSize="sm"
                className="!text-xl !leading-5 !text-yellow-700"
              >
                info
              </Icon>
              <Text as="h3" variant="action">
                Catatan
              </Text>
            </div>
            <Text variant="body-sm">
              Mulai periode berikutnya (
              <CardPeriod
                period={nextPeriodNumber}
                year={nextPeriodYear}
                separator="-"
              />
              ) dan seterusnya, Anda diharapkan melakukan
              {isGuru ? ' Pelaksanaan ' : ' Penilaian '}
              Pengelolaan Kinerja sesuai jadwal seharusnya.
            </Text>
          </aside>
        </div>
        <footer className="px-6 pb-6">
          <Button
            size="lg"
            fullWidth
            color="black"
            onClick={() => dialogProps.onClose?.()}
          >
            Saya mengerti
          </Button>
        </footer>
      </DialogContent>
    </Dialog>
  )
}
