import React from 'react'
import { getCookie, setCookie } from 'cookies-next'

import { useAPIGetUserGroupsByUserId } from 'api/user-groups/useAPIUserGroups'
import { useAuth } from 'utils/auth'

type IUserGroups = {
  id: string
  name: string
  type: string
}

type GroupExistence = {
  groupName: string
  exist: boolean
}

type ListGroupExistence = GroupExistence[]

type UserGroupsContextType = {
  userGroups: IUserGroups[] | [] | null
  isLoading: boolean
  error: any
  refetch: () => void
  getUserGroupsByName: (groupName: string) => unknown
  isUserHasGroup: (groupName: string) => boolean
  getUserGroupsByNameList: (groupNames: string[]) => unknown[]
  isUserHasListOfGroup: (groupNames: string[]) => boolean
  checkUserGroupsByNameList: (groupNames: string[]) => ListGroupExistence
  checkUserHasSomeGroup: (groupNames: string[]) => boolean
}

type UserGroupsCookieContent = {
  groups?: IUserGroups[]
  userId?: string
}

const userGroupsContextDefaultValues: UserGroupsContextType = {
  userGroups: [],
  getUserGroupsByName: () => null,
  isUserHasGroup: () => false,
  isUserHasListOfGroup: () => false,
  getUserGroupsByNameList: () => [],
  checkUserGroupsByNameList: () => [],
  isLoading: false,
  error: null,
  refetch: () => {},
  checkUserHasSomeGroup: () => false,
}

export const UserGroupsContext =
  React.createContext<UserGroupsContextType | null>(
    userGroupsContextDefaultValues
  )

UserGroupsContext.displayName = 'UserGroupsContext'

export function useUserGroupsContext() {
  const context = React.useContext(UserGroupsContext)
  if (!context) {
    throw new Error(
      'useUserGroupsContext must be used within a UserGroupsContext'
    )
  }
  return context
}

export function UserGroupsProvider({ children }) {
  const { session } = useAuth()

  const storedUserGroups = React.useMemo(() => {
    const userGroupsInCookie = getCookie('USER_GROUPS')
    return userGroupsInCookie ? JSON.parse(userGroupsInCookie as string) : null
  }, [])

  const setStoredUserGroups = (data: UserGroupsCookieContent) =>
    setCookie('USER_GROUPS', data, {
      maxAge: 60 * 60, // 1 hour
    })

  React.useEffect(() => {
    if (session?.user?.id !== storedUserGroups?.userId) {
      setStoredUserGroups(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, isLoading, isSuccess, refetch } = useAPIGetUserGroupsByUserId(
    session?.user?.id,
    {
      enabled: Boolean(session?.user?.id) && !storedUserGroups?.groups,
      onSuccess: (data) => {
        setStoredUserGroups({
          groups: data?.data,
          userId: session?.user?.id,
        })
      },
    }
  )

  const groupsByName = React.useMemo(() => {
    const groups = data?.data ? data.data : storedUserGroups?.groups
    return new Map(groups?.map((group) => [group.name, group]))
  }, [data?.data, storedUserGroups?.groups])

  const getUserGroupsByName = React.useCallback(
    (groupName: string) => {
      return groupsByName.get(groupName)
    },
    [groupsByName]
  )

  const getUserGroupsByNameList = React.useCallback(
    (groupNames: string[]) => {
      return groupNames.map((groupName) => groupsByName.get(groupName))
    },
    [groupsByName]
  )

  const isUserHasGroup = React.useCallback(
    (groupName: string) => {
      return groupsByName.has(groupName)
    },
    [groupsByName]
  )

  const isUserHasListOfGroup = React.useCallback(
    (groupNames: string[]) => {
      return groupNames.every((groupName) => groupsByName.has(groupName))
    },
    [groupsByName]
  )

  const checkUserGroupsByNameList = React.useCallback(
    (groupNames: string[]) => {
      return groupNames.map((groupName) => ({
        groupName,
        exist: groupsByName.has(groupName),
      }))
    },
    [groupsByName]
  )

  const checkUserHasSomeGroup = React.useCallback(
    (groupNames: string[]) => {
      return groupNames.some((groupName) => groupsByName.has(groupName))
    },
    [groupsByName]
  )

  return (
    <UserGroupsContext.Provider
      value={{
        userGroups: data?.data || storedUserGroups?.groups || [],
        getUserGroupsByName,
        getUserGroupsByNameList,
        isUserHasGroup,
        isUserHasListOfGroup,
        checkUserGroupsByNameList,
        isLoading,
        error: !isSuccess || !storedUserGroups?.groups,
        refetch,
        checkUserHasSomeGroup,
      }}
    >
      {children}
    </UserGroupsContext.Provider>
  )
}
