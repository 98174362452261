import create from 'zustand'

import { devtools } from 'utils/zustand'

import type { State } from 'zustand'
import type { TToggleKeyValuePair } from 'configs/toggle-feature'

export type TTogglesStore = State & {
  toggles?: TToggleKeyValuePair
  setToggles?: (toggles: TToggleKeyValuePair) => void
}

const initialState: Partial<TTogglesStore> = {
  toggles: null,
}

export const useTogglesStore = create<TTogglesStore>(
  devtools((set) => ({
    ...initialState,
    setToggles: (toggles) =>
      set({
        toggles,
      }),
  }))
)
