import React from 'react'
import ls from 'localstorage-slim'

import { useAuth } from 'utils/auth'
import { useAPIParticipateAllExperiments } from 'api/experiments/useAPIExperiments'
import { IS_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED } from 'configs/toggle'
import { useGetDeviceId } from 'api/device-id/useAPIDeviceId'

type TExperiment = {
  name: string
  properties: Record<string, never>
  treatment: string
}
type TExperimentsContextType = {
  isFetchExperimentsSuccess: boolean
  getExperimentByName: (experimentName: string) => TExperiment | null
}

const experimentsContextDefaultValues: TExperimentsContextType = {
  isFetchExperimentsSuccess: false,
  getExperimentByName: () => null,
}

export const ExperimentsContext =
  React.createContext<TExperimentsContextType | null>(
    experimentsContextDefaultValues
  )

ExperimentsContext.displayName = 'ExperimentsContext'

export function useExperimentsContext() {
  const context = React.useContext(ExperimentsContext)
  if (!context) {
    throw new Error(
      'useExperimentsContext must be used within a ExperimentsContext'
    )
  }
  return context
}

export const ExperimentsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isFetchExperimentsSuccess, setFetchExperimentsSuccess] =
    React.useState<boolean>(false)
  const { session } = useAuth()
  const userId = session?.user?.id ?? ''
  const { data: deviceId, isSuccess: isFetchDeviceIdSuccess } = useGetDeviceId()

  const localStorageKey = `guru-experiments${
    Boolean(userId) ? `-${userId}` : ''
  }`
  const localStorageData = ls.get(localStorageKey, { decrypt: true })
  const isCached = Boolean(localStorageData)
  const { data, isSuccess } = useAPIParticipateAllExperiments(
    userId,
    deviceId,
    {
      enabled:
        IS_CONVERSATION_WITH_AI_NEW_ENTRY_POINT_ENABLED &&
        Boolean(userId) &&
        isFetchDeviceIdSuccess &&
        !isCached,
    }
  )

  const getExperiments = () => {
    try {
      // Get experiment data from Local Storage
      if (isCached) {
        return localStorageData
      }

      // Get experiment data from API & set cache
      if (isSuccess) {
        ls.set(localStorageKey, data?.experiments, {
          encrypt: true,
          ttl: 60 * 60, // 1 hour
        })
        return data?.experiments
      }

      return []
    } catch (e) {
      return []
    }
  }

  const getExperimentByName = (experimentName: string): TExperiment | null => {
    const experiments = getExperiments()
    if (!isCached && !isSuccess) {
      return {
        name: experimentName,
        properties: {},
        treatment: 'control',
      }
    }

    return (experiments ?? []).find(
      (experiment) => experiment?.name === experimentName
    )
  }

  React.useEffect(() => {
    if (isCached) {
      setFetchExperimentsSuccess(true)
    } else {
      setFetchExperimentsSuccess(isSuccess)
    }

    return () => {
      setFetchExperimentsSuccess(false)
    }
  }, [isCached, isSuccess])

  return (
    <ExperimentsContext.Provider
      value={{
        isFetchExperimentsSuccess,
        getExperimentByName,
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  )
}
