import type { CommonResponse } from 'types/api'
import type {
  ResponseFollowUpReflectionEvaluationDocument,
  TFollowUpReflectionEvaluationDocumentStates,
} from 'types/kinerja-pelaksanaan'

export const dummyFollowUpReflectionEvaluationDocument: CommonResponse<ResponseFollowUpReflectionEvaluationDocument> =
  {
    data: {
      state: '' as TFollowUpReflectionEvaluationDocumentStates,
      detail: {
        additionalReflectionEvaluations: [],
        reflectionEvaluations: [],
      },
    },
    meta: {},
  }
