import React from 'react'
import flattenNodes from 'react-keyed-flatten-children'
import clsx from 'clsx'
import Link from 'next/link'
import NextImage from 'next/image'

import type { ButtonProps } from '@wartek-id/button-v1'
import { Button } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text'

import type { NextHref } from 'types/next'

interface EmptyStateProps extends React.HTMLAttributes<HTMLDivElement> {
  withHeader?: boolean
  fullscreen?: boolean
  title: string
  description: string
}

interface EmptyStateActionProps extends ButtonProps {
  children: React.ReactNode
  onClick?: (event: any) => void
  href?: string | NextHref
  className?: string
  color?: 'blue' | 'black' | 'white'
}

export function EmptyStateAction(props: EmptyStateActionProps) {
  return (
    <Button
      fullWidth
      color={props.color || 'blue'}
      size="md"
      className={props.className ?? 'w-full mb-4'}
      {...props}
    />
  )
}

export function EmptyState({
  withHeader = true,
  fullscreen = true,
  title,
  description,
  className,
  children,
  ...props
}: EmptyStateProps) {
  const containerClasses = clsx(
    'flex flex-col justify-center text-sm',
    fullscreen
      ? withHeader
        ? 'h-full--without-header px-5'
        : 'h-full px-5'
      : '',
    className
  )
  return (
    <article className={containerClasses} {...props}>
      <Text as="h2" variant="heading-md" className="mb-2">
        {title}
      </Text>
      <Text className="pb-4" color="subdued">
        {description}
      </Text>
      {React.Children.map(flattenNodes(children), (child) => {
        if (typeof child === 'object' && child.type === EmptyStateAction) {
          const { href, ...props } = child.props as EmptyStateActionProps
          if (href) {
            return (
              <Link href={href} passHref>
                <EmptyStateAction {...props} />
              </Link>
            )
          }
          return <EmptyStateAction {...props} />
        }
      })}
    </article>
  )
}

interface EmptyStateImageProps {
  imageSrc: string
}
export function EmptyStateImage({
  imageSrc = '',
  ...props
}: EmptyStateImageProps) {
  return (
    <div className="flex justify-center items-center mb-4">
      <NextImage {...props} src={imageSrc} alt="" width="120" height="120" />
    </div>
  )
}

export function EmptyStateWithIllustration({
  withHeader = true,
  fullscreen = true,
  title,
  description,
  className,
  children,
  ...props
}: EmptyStateProps) {
  const containerClasses = clsx(
    'flex flex-col justify-center text-sm',
    fullscreen
      ? withHeader
        ? 'h-full--without-header px-5'
        : 'h-full px-5'
      : '',
    className
  )
  return (
    <article className={containerClasses} {...props}>
      {React.Children.map(flattenNodes(children), (child) => {
        if (!React.isValidElement(child)) {
          return
        }

        if (typeof child === 'object' && child.type === EmptyStateImage) {
          const props = child.props as EmptyStateImageProps
          return <EmptyStateImage {...props} />
        }
        if (child?.props?.as === 'EmptyStateImageSvg') {
          return (
            <div className="mx-auto mb-4">
              <child.type {...child?.props} />
            </div>
          )
        }
      })}

      <Text as="h2" variant="heading-md" className="mb-2">
        {title}
      </Text>
      <Text className="pb-4" color="subdued">
        {description}
      </Text>
      {React.Children.map(flattenNodes(children), (child) => {
        if (typeof child === 'object' && child.type === EmptyStateAction) {
          const { href, ...props } = child.props as EmptyStateActionProps
          if (href) {
            return (
              <Link href={href} passHref>
                <EmptyStateAction {...props} />
              </Link>
            )
          }
          return <EmptyStateAction {...props} />
        }
      })}
    </article>
  )
}
