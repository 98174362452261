import { useQuery } from 'react-query'

import { api, useQueryApi } from 'utils/api'
import { useFiltersStore } from 'stores/filters'
import { useToolkitFilterStore } from 'stores/toolkitFilters'

import type { TClass, TFaseGroup } from 'types/classAndPhases'
import type { TToolkitFilters } from 'types/filters'
import type { UseQueryOptions } from 'react-query'
import type { Filters } from 'types/entities'

const CDNUrl = process.env.NEXT_PUBLIC_TOOLKIT_CDN
const CDNFiltersJsonUrl = `${CDNUrl}/assets/filters.json`
export interface ResponseSuccessToolkitFilter {
  data: TToolkitFilters
  meta: any
}

export function useAPIGetFilters(opts?: UseQueryOptions<Filters>) {
  const setFilters = useFiltersStore((state) => state.setFilters)
  return useQuery<Filters>(
    'get-filters',
    (): Promise<Filters> => {
      return api<Filters>('/teachers/v1alpha1/assessment/filters/getall')
    },
    {
      ...opts,
      onSuccess: (data) => {
        if (data) {
          setFilters(data)
        }
      },
    }
  )
}

export interface IUseAPIGetToolkitFiltersProps
  extends UseQueryOptions<ResponseSuccessToolkitFilter> {
  isFromBucket?: boolean
}

export const useAPIGetToolkitFilters = ({
  isFromBucket,
  ...opts
}: IUseAPIGetToolkitFiltersProps = {}) => {
  const setFilters = useToolkitFilterStore((state) => state.setFilters)
  return useQueryApi<ResponseSuccessToolkitFilter>(
    isFromBucket ? CDNFiltersJsonUrl : '/curriculums/toolkits/filters',
    {
      queryConfig: {
        staleTime: 3600 * 1000,
        cacheTime: 3600 * 1000,
        useErrorBoundary: false,
        onSuccess: (data) => {
          if (data?.data) {
            setFilters(data?.data)
          }
        },
        ...opts,
      },
    }
  )
}

interface IUseAPIGetSubjectByClassAndSchoolTypeProps {
  class: TClass[]
  phaseGroup: TFaseGroup
}

export type IV2SubjectType = {
  label: string
  value: string
  domains: string[]
  school_type: TFaseGroup
  class: TClass
}

export interface IUseAPIGetSubjectByClassAndSchoolTypeResponse {
  more_subject: IV2SubjectType[]
}

export const useAPIGetSubjectByClassAndSchoolType = ({
  class: klass,
  phaseGroup,
}: IUseAPIGetSubjectByClassAndSchoolTypeProps) => {
  return useQueryApi<{ data: IUseAPIGetSubjectByClassAndSchoolTypeResponse }>(
    '/curriculums/v2/public/subjects',
    {
      queryConfig: {
        staleTime: 3600 * 1000,
        cacheTime: 3600 * 1000,
      },
      axiosConfig: {
        params: {
          class: klass,
          school_type: phaseGroup,
        },
      },
    }
  )
}
