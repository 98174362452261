import type { SamplingContext } from '@sentry/types'

const ignoredPages = ['/404', '/login', '/logout']
const ignoredUserAgents = ['androidmobile PMM']

export const allowSampling = (samplingContext: SamplingContext): boolean => {
  const guruUrlObj = new URL(process.env.NEXT_PUBLIC_BASE_URL)
  const allowedHost = guruUrlObj.host

  const page = samplingContext?.transactionContext?.name || ''
  const userAgent = window.navigator.userAgent
  const host = window.location.host
  const isDev = process.env.NODE_ENV === 'development'

  return (
    !isDev &&
    allowedHost === host &&
    !ignoredPages.includes(page) &&
    !ignoredUserAgents.some((item) => userAgent.includes(item))
  )
}
