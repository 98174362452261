import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

import { SATU_EVENT_CONFIG } from 'configs/auth'
import { getSession } from 'utils/auth'
import fetchWithTimeout from 'utils/fetchWithTimeout'
import { useAssessmentTrackerStore } from 'stores/tracker'
import { enabledAssessmentTrackers } from 'app/AssessmentPage/data'

import type { TAssessmentEventName } from 'types/assessment'
import type { AxiosResponse } from 'axios'

interface CustomParams {
  [key: string]: any
}

export interface SETrackEventCallbackArgs {
  status: 'success' | 'failed'
  data: AxiosResponse | any
}

export interface SETrackEventArgs {
  eventName: string
  eventParams?: CustomParams
  sendDefaultParams?: boolean
  sendUserData?: boolean
  callback?: (props: SETrackEventCallbackArgs) => void
  timeout?: number
  keepAlive?: boolean
  sendAttributes?: boolean
  sendUserAgent?: boolean
}

export type SETransformAttrsArgs = Record<
  string,
  | string
  | number
  | [string | number | boolean, 'string' | 'int' | 'float' | 'double' | 'bool']
>

export interface SETransformedDataAttrs {
  value: {
    string_value?: string
    int_value?: number
    float_value?: number
    double_value?: number
    bool_value?: boolean
  }
}

export type SETransformedAttrs = SETransformedDataAttrs[]

const getUserData = () => {
  const session = getSession()
  return {
    user_id: session?.user?.id,
    // // FIXME FOR LATER
    // user_email: session?.user?.email,
    // session_id: null,
    // application_version: null,
  }
}
/**
 * This is where you include all of your predefined parameters, eg. user_id, timestamp, etc.
 */
const getDefaultData = () => {
  return {
    id: uuidv4(),
    timestamp: new Date().toISOString(),
  }
}

const getPlatformData = () => {
  return {
    platform: window.AndroidHandler ? 'APP' : 'WEB',
  }
}

const isTypeOfAssessmentEventName = (
  eventName: string
): eventName is TAssessmentEventName => {
  return enabledAssessmentTrackers.includes(eventName)
}

export interface IUserSatuEventTrackerProps {
  mode?: 'default' | 'mengajar'
}

export const useSatuEventTracker = ({
  mode = 'default',
}: IUserSatuEventTrackerProps = {}) => {
  const {
    enabled: isEnabled,
    debugEnabled,
    url,
    username: defaultUsername,
    password: defaultPassword,
    mengajar_username,
    mengajar_password,
  } = SATU_EVENT_CONFIG

  const USERNAME_MAPPING = {
    default: defaultUsername,
    mengajar: mengajar_username,
  }

  const PASSWORD_MAPPING = {
    default: defaultPassword,
    mengajar: mengajar_password,
  }

  const username = USERNAME_MAPPING[mode || 'default']
  const password = PASSWORD_MAPPING[mode || 'default']

  /**
   * Use this method to transform your standard `key:value` objects into a SatuEvent array-ready attributes
   *
   * @remarks
   *
   * In general, this function will return the following structure for every `key` you specified.
   * However, the `value` may only return one value based on its type.
   *
   * ```ts
   * {
   *    "key": "",
   *    "value": {
   *      "string_value": "1",
   *      "int_value": 1,
   *      "float_value": 1.00,
   *      "double_value": 1.00,
   *    }
   * }
   * ```
   *
   * @example
   * You can directly assign the `value` next to its `key`

   * ```ts
   * const attrs = { category: trackerParams.category }
   * SETransformAttrs(attrs);
   * ```
   *
   * @example
   * If you need to cast the value you can set the value as an array, where the first value should be the value itself.
   * While the second value, can be anything between `string` or `int` or `float` or `double`.
   *
   * ```ts
   * const attrs = { num_cp_blocks: [trackerParams.numOfBlocks, 'int'] }
   * SETransformAttrs(attrs);
   * ```
   *
   */
  const SETransformAttrs = (
    attrs: SETransformAttrsArgs
  ): SETransformedAttrs => {
    return Object.keys(attrs).map((key) => {
      const value = attrs?.[key]

      let stringValue: string,
        intValue: number,
        floatValue: number,
        doubleValue: number,
        boolValue: boolean

      // If not array
      if (!Array.isArray(value)) stringValue = value?.toString()
      // If array
      else {
        const strValue = value[0]?.toString()
        if (value[1] === undefined) stringValue = strValue
        else if (value[0] !== undefined && value[1] !== undefined) {
          if (value[1] === 'string') {
            stringValue = strValue
          } else if (value[1] === 'int' && strValue !== '') {
            intValue = parseInt(strValue, 10)
            // To handle signed/negative zero, if the parsed strValue is rounded to 0
            if (intValue === -0) intValue = 0

            // If not a number, return undefined
            if (isNaN(intValue)) intValue = undefined
          } else if (value[1] === 'float' && strValue !== '') {
            floatValue = parseFloat(strValue)

            // If not a number, return undefined
            if (isNaN(floatValue)) floatValue = undefined
          } else if (value[1] === 'double' && strValue !== '') {
            doubleValue = parseFloat(strValue)

            // If not a number, return undefined
            if (isNaN(doubleValue)) doubleValue = undefined
          } else if (value[1] === 'bool' && strValue !== '') {
            boolValue = Boolean(value[0])

            // If not a boolean, return undefined
            if (typeof value[0] !== 'boolean') boolValue = undefined
          }
        }
      }

      return {
        key,
        value: {
          string_value: stringValue,
          int_value: intValue,
          float_value: floatValue,
          double_value: doubleValue,
          bool_value: boolValue,
        },
      }
    })
  }

  /**
   * @param {Object}    props - Satu Event properties
   * @param {string}    props.eventName - The event name
   * @param {string}    props.eventParams - The event params
   * @param {bolean}    props.sendDefaultParams - Should the IDs included within the event? eg. id, timestamp
   * @param {bolean}    props.sendUserData - Should the user data included within the event? eg. user_id
   * @param {function}  [props.callback] - [Optional] The callback function that the tracker will call after the request is send (both success or failed)
   * @param {number}    [props.timeout] - Timeout in miliseconds. Recommended to be set for some cases
   */
  const SETrackEvent = ({
    eventName,
    eventParams,
    sendDefaultParams = true,
    sendUserData = false,
    callback = () => {},
    timeout,
    keepAlive,
    sendAttributes = true,
  }: SETrackEventArgs) => {
    if (!isEnabled || !url) {
      if (debugEnabled) console.error('[SatuEvent] Tracker disabled')
      return
    }

    const eventParamAttrs = eventParams?.attributes || []

    let params = {
      ...eventParams,
    }

    if (sendAttributes) {
      params.attributes = [
        // Pass the previously specified attributes
        ...eventParamAttrs,

        // Include `user_agent` as one of the attribute
        ...SETransformAttrs({
          user_agent: [window?.navigator?.userAgent, 'string'],
        }),
      ]
    }

    if (sendDefaultParams) {
      params = { ...getDefaultData(), ...params }
      if (mode === 'mengajar') params = { ...getPlatformData(), ...params }
    }
    if (sendUserData) params = { ...params, ...getUserData() }

    if (isTypeOfAssessmentEventName(eventName)) {
      useAssessmentTrackerStore.setState({
        eventTrigger: eventName,
        eventTriggerId: params['id'],
      })
    }

    if (debugEnabled) {
      // eslint-disable-next-line no-console
      console.log('[SatuEvent]', eventName, JSON.stringify(params))
    }

    const requestBody = {
      event_name: eventName,
      ...(Object.keys(params)?.length > 0
        ? { data: JSON.stringify(params) }
        : {}),
    }

    // allow tracker requests to outlive the page (when redirected) and no need to wait a callback
    // reference: https://developer.mozilla.org/en-US/docs/Web/API/fetch#keepalive
    if (keepAlive) {
      fetchWithTimeout(url, {
        timeout,
        keepalive: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
        body: JSON.stringify(requestBody),
      })
      return
    }

    axios
      .post(url, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
        auth: {
          username: username,
          password: password,
        },
        timeout,
      })
      .then((res) => {
        callback?.({ status: 'success', data: res })
      })
      .catch((error) => {
        console.error(error)
        callback?.({ status: 'failed', data: error })
      })
  }

  return {
    SETrackEvent,
    SETransformAttrs,
  }
}
