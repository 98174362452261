import { useState, useEffect } from 'react'

import { isNavigator } from 'utils/browser'

const getOnlineStatus = () =>
  isNavigator ? window.navigator?.onLine ?? true : false

export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean>(getOnlineStatus())

  const setOnline = () => setIsOnline(true)
  const setOffline = () => setIsOnline(false)

  useEffect(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  return isOnline
}
