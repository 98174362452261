import type { CommonResponse } from 'types/api'
import type { ResponseFollowUpReflectionDocument } from 'types/kinerja-pelaksanaan'

export const dummyFollowUpReflectionDocument: CommonResponse<ResponseFollowUpReflectionDocument> =
  {
    data: {
      detail: {
        additionalChallenge:
          'Saya sulit mengajak guru lain praktik simulasi setelah jam mengajar.',
        challenge:
          'Saya sulit membagi jam mengajar dengan menyelesaikan modul Pelatihan Mandiri, modul kurang sesuai, susah dapet referensi yang relevan.',

        additionalInspiration: 'Metode baru dalam mengelompokkan siswa.',
        inspiration: 'Cara mempraktikan restitusi dan teori coaching.',

        additionalAchievement:
          'Saya mendapat umpan balik yang menyadarkan bahwa murid khawatir masuk kelompok yang tidak efektif.',
        achievement:
          'Saya mendapat umpan balik yang menyadarkan bahwa murid khawatir masuk kelompok yang tidak efektif.',

        additionalImprovementPlan:
          'Minta bantuan atasan untuk mengumumkan di rapat.',
        improvementPlan: 'Minta bantuan atasan untuk mengumumkan di rapat.',
      },
      state: 'SUBMITTED',
    },
    meta: {},
  }
