import clsx from 'clsx'
import React from 'react'

import { BottomNav } from 'components/BottomNav'
import { Header as BaseHeader } from 'components/Header'
import AndroidHandler from 'utils/androidHandler'
import { useOnlineStatus } from 'utils/hooks/useOnlineStatus'

import type { NavItem } from 'components/BottomNav'
import type { ComponentType } from 'react'

import ClassIcon from 'assets/icons/class.svg'
import HomeIcon from 'assets/icons/home.svg'
// import ProfileIcon from 'assets/icons/profile.svg'

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  hasHeader?: boolean
  headerHasBackButton?: boolean
  headerTitle?: string
  hasBottomNav?: boolean
  backHref?: string
  header?: ComponentType<{ pageProps?: Record<string, any> }>
  pageProps?: Record<string, unknown>
}

const navItems: NavItem[] = [
  { href: '/home', icon: HomeIcon, text: 'Beranda', disabled: false },
  {
    href: '/learning-group',
    icon: ClassIcon,
    text: 'Kelas',
    disabled: false,
  },
  // { href: '/account', icon: ProfileIcon, text: 'Akun', disabled: true },
]

export const Layout = ({
  children,
  hasHeader = false,
  hasBottomNav = false,
  headerTitle = '',
  headerHasBackButton = false,
  className,
  header: PropHeader,
  pageProps,
}: LayoutProps) => {
  const androidHandler = AndroidHandler()
  const isOnline = useOnlineStatus()
  const Header =
    PropHeader ??
    (() =>
      BaseHeader({
        title: headerTitle,
        hasBackButton: headerHasBackButton,
        pageProps: pageProps,
      }))

  return (
    <div
      className={clsx(
        'max-w-xl m-auto relative',
        hasHeader && 'mt-14',
        className
      )}
      style={{
        height: hasHeader ? 'calc(100vh - 3.5rem)' : '100vh',
      }}
    >
      {hasHeader && <Header pageProps={pageProps} />}
      <div className={clsx('h-full', hasBottomNav && 'pb-14')}>{children}</div>
      {hasBottomNav && !androidHandler.enabled && (
        <BottomNav navItems={navItems} disabled={!isOnline} />
      )}
    </div>
  )
}

export default Layout
