import { captureMessage, setUser } from '@sentry/nextjs'
import axios from 'axios'
import { useRouter } from 'next/router'
import { stringify } from 'query-string'
import React from 'react'

import { useMutateAPILogout } from 'api/auth/useAPIAuth'
import { baseUrl } from 'configs/api'
import {
  APP_LOGIN_STORAGE_KEY,
  GURU_TOKEN_GOOGLE_OAUTH_CONFIG,
} from 'configs/auth'
import useGoogleAuthorize, {
  useGoogleIdentityServicesAuthorize,
} from 'utils/hooks/useGoogleAuthorize'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { GUIDE_PERILAKU_KERJA_STORAGE_KEY } from 'app/KinerjaPage/pages/PelaksanaanV2/PenilaianKinerja/RincianPenilaianKinerja'

import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import type { ApiStatus } from 'types/api'
import type { Session } from 'types/auth'
import type { AuthContextType, UseAuthProvider } from './types'

const AuthContextDefaultValue: AuthContextType = {
  session: null,
  login: () => {},
  loginScriptIsLoaded: false,
  logout: () => {},
  validateTokenError: null,
  lastUsedEmailDomain: null,
  error: null,
}

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextDefaultValue
)
AuthContext.displayName = 'AuthContext'

let targetPath: string | null = null

export function useAuthProvider(immediate: boolean = true): UseAuthProvider {
  const [isLoggingOut, setLoggingOut] = React.useState<boolean>(false)
  const { mutate } = useMutateAPILogout()

  const {
    run,
    session,
    status,
    error: validateTokenError,
    resetSession,
  } = useValidateToken()
  const router = useRouter()
  const { query } = router ?? {}

  const [, , removeValue] = useLocalStorage('community.registration', null)
  const [, , removeCompetencyLevel] = useLocalStorage(
    'assessment-competency-level',
    ''
  )
  // Storage for showing Guide of Perilaku Kerja
  const [, , removeGuidePerilakuKerja] = useLocalStorage(
    GUIDE_PERILAKU_KERJA_STORAGE_KEY,
    null
  )
  // Remove the selected Karya untuk Anda preference from the storage
  // Check on: https://gitlab.com/wartek-id/guru/komunitas-belajar/komunitas-belajar-fe
  const [, , removeKaryaForYouPreference] = useLocalStorage(
    'KARYA_FOR_YOU_PREFERENCE_STORAGE_KEY',
    null
  )

  const [error, setError] = React.useState(null)
  const [lastUsedEmailDomain, setLastUsedEmailDomain] = React.useState(null)

  React.useEffect(() => {
    if (immediate) {
      run(null)
    }
    // eslint-disable-next-line
  }, [])

  const { authorize: login, loaded: loginScriptIsLoaded } = (
    process.env.NEXT_PUBLIC_GOOGLE_IDENTITY_SERVICES_ENABLED === 'true'
      ? useGoogleIdentityServicesAuthorize
      : useGoogleAuthorize
  )({
    ...GURU_TOKEN_GOOGLE_OAUTH_CONFIG,
    onSuccess({ idToken, emailDomain }) {
      run(idToken)
      setLastUsedEmailDomain(emailDomain)
    },
    onFailure(loginError) {
      if (loginError !== 'popup_closed_by_user') {
        const err = new Error(
          // Part of this string is used for new relic alert.
          // Double check with new relic policy dashboard if you want to modify
          `${loginError} (Login failed due to cannot get idToken from Google)`
        )
        window?.newrelic?.noticeError(err)
      }
      setError(loginError)
    },
  })

  const logout = async () => {
    if (isLoggingOut || targetPath) {
      return
    }

    setLoggingOut(true)

    mutate(null, {
      onSettled: () => {
        resetSession()
        removeValue()
        removeKaryaForYouPreference()
        removeCompetencyLevel()
        removeGuidePerilakuKerja()

        if (query?.target) {
          const { target, ...params } = query
          targetPath = target as string

          if (targetPath === '/komunitas') {
            window.location.href = `${
              process.env.NEXT_PUBLIC_BASE_URL
            }/komunitas?${stringify(params)}`
            return
          }

          targetPath += `?${stringify(params)}`
          window.location.replace(targetPath)
          return
        }

        window.location.replace('/')
      },
      onError: (e: any) => {
        if (process.env.NODE_ENV !== 'production') return

        const logPrefix = '[Logout API]'
        const message = e?.message ?? 'Unknown error'
        captureMessage(`${logPrefix}: ${message}`, 'error')
      },
    })
  }

  return {
    session,
    login,
    loginScriptIsLoaded,
    logout,
    status,
    error,
    validateTokenError,
    resetSession,
    lastUsedEmailDomain,
  }
}

export function AuthProvider(props) {
  const {
    session,
    login,
    loginScriptIsLoaded,
    logout,
    status,
    error,
    validateTokenError,
    lastUsedEmailDomain,
  } = useAuthProvider()

  const value = React.useMemo(
    () => ({
      session,
      login,
      loginScriptIsLoaded,
      logout,
      error,
      validateTokenError,
      lastUsedEmailDomain,
    }),
    [
      session,
      login,
      loginScriptIsLoaded,
      logout,
      error,
      validateTokenError,
      lastUsedEmailDomain,
    ]
  )

  if (['idle', 'loading'].includes(status)) {
    return null
  } else {
    return <AuthContext.Provider value={value} {...props} />
  }
}

function useValidateToken() {
  const [storedSession, setSession, removeSession] = useLocalStorage(
    APP_LOGIN_STORAGE_KEY,
    null
  )
  const [status, setStatus] = React.useState<ApiStatus>('idle')
  const [error, setError] = React.useState(null)

  const run = async (idToken: string | null) => {
    setStatus('loading')

    try {
      const session: Session = await validateToken(idToken)
      if (!!session) {
        setSession(session)
      }

      const userId = storedSession?.user?.id ?? session?.user?.id ?? null
      if (userId) {
        setUser({ id: userId })
      }

      setStatus('success')
    } catch (err) {
      setError(err)
      setStatus('error')
    }
  }

  const resetSession = () => {
    setUser(null)
    removeSession()
    setStatus('idle')
  }

  return {
    run,
    session: storedSession,
    status,
    error,
    resetSession,
  }
}

async function validateToken(idToken: string | null): Promise<Session | null> {
  if (idToken) {
    try {
      const axiosConfig: AxiosRequestConfig = {
        url: `${baseUrl}/guru/teachers/v1alpha2/login`,
        method: 'POST',
        data: {
          grantType: 'GOOGLE',
          token: idToken,
        },
      }
      const { data } = (await axios(axiosConfig)) as AxiosResponse<{
        data: Session
      }>
      return data.data
    } catch (error) {
      const err = new Error(
        // Part of this string is used for new relic alert.
        // Double check with new relic policy dashboard if you want to modify
        `${error} (Login failed due to cannot exchange idToken to guruToken)`
      )
      window?.newrelic?.noticeError(err)
      throw error
    }
  } else {
    return null
  }
}
