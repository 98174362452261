export const checkUrlAvailability = async (url) => {
  try {
    const response = await fetch(
      `/api/get-url-availability?url=${window.encodeURI(url)}`
    )

    if (response.ok) {
      const jsonResponse = await response.json()
      return !!jsonResponse?.exist
    }

    throw new Error('Failed to fetch url')
  } catch (err) {
    return false
  }
}

export const isValidURL = (value: string): boolean => {
  if (!Boolean(value)) return false

  const res = value.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  return res !== null
}

export const setURLQuery = (url: string, queries: Record<string, any>) => {
  if (Object.keys(queries ?? {}).length > 0) {
    const modifiedURL = new URL(url)
    const params = modifiedURL.searchParams

    Object.keys(queries).map((key) => {
      params.set(key, queries[key])
    })

    return modifiedURL.toString()
  }

  return url
}

export const assetUrl = (file: string): string => {
  return process.env.NEXT_PUBLIC_CDN_ASSETS_ENABLED === 'true'
    ? `${process.env.NEXT_PUBLIC_STATIC_GCS_CDN}${file}`
    : file
}
