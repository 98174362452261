import type { CommonResponse } from 'types/api'
import type { ResponseFollowUpDocument } from 'types/kinerja-pelaksanaan'

export const dummyFollowUpDocument: CommonResponse<ResponseFollowUpDocument> = {
  data: {
    detail: {
      additionalFollowUpEffort:
        'Melakukan praktik simulasi dengan rekan guru lainnya.',
      additionalFollowUpPeriod: 'Mei-Juni.',
      additionalFollowUpTarget:
        'Saya ingin dapat melakukan strategi pengelompokkan yang efektif.',
      additionalSupportNeed:
        'Sumber daya untuk melakukan kegiatan belajar/praktik',
      followUpPeriod: 'Mei-Juni.',
      followUpTargets: [
        {
          id: 'id-target-1',
          indicator: 'Pembelajaran efektif yang berpusat pada peserta didik',
          subIndicator:
            'Instruksi pembelajaran yang mencakup strategi dan komunikasi untuk menumbuhkan minat dan nalar kritis peserta didik',
          learningModule: {
            id: 'id-lm-1',
            title: 'Ide-Ide Praktis Penggunaan Alat Matematika',
            url: '/pelatihan-mandiri/modul/168?from=topik&menuId=44&topik=44&topik_name=Numerasi%3A%20Ide-Ide%20Praktis%20Pembelajaran%20%5BKemitraan%20Dengan%20Inovasi%5D',
          },
        },
      ],
      selfReflection:
        'Saya kesulitan karena banyak murid yang bersikeras membuat kelompok sendiri.',
      supportNeed: 'Sumber daya untuk melakukan kegiatan belajar/praktik',
    },
    state: 'SUBMITTED',
  },
  meta: {},
}
