import usePeriodContext from 'app/KinerjaShared/hooks/usePeriodContext'
import { useSatuEventTracker } from 'utils/satuEvent'

const SATU_EVENT_NAME = 'guru_skp_pelaksanaan_fe_user_action_v1'

export type ScreenName =
  | '' // Allows empty string
  | 'homepage_pengelolaan'
  | 'pelaksanaan_hub'
  | 'form_a'
  | 'form_b'
  | 'form_c'
  | 'form_d'
  // @deprecated use homepage_penilaian_ks instead
  | 'ks_penilaian_home'
  // @deprecated use pelaksanaan_hub_ks instead
  | 'ks_penilaian_hub'
  | 'ks_periksa_skp'
  | 'ks_rincian_penilaian'
  | 'ks_kelengkapan_dokumen'
  | 'homepage_penilaian_ks'
  | 'pelaksanaan_hub_ks'
  | 'form_b_ks'
  | 'form_c_ks'
  | 'form_e3_ks'
  | 'form_d_ks'
  | 'ks_perilaku_kerja'
  | 'ks_detail_kelengkapan_dokumen'
  | 'ks_penetapan_kinerja'
  | 'pusat_notifikasi'
  | 'detail_riwayat_kinerja'

export type ActivityType =
  | 'pelaksanaan_click_cek_langkah_observasi'
  | 'pelaksanaan_open_pelaksanaan_homepage'
  | 'pelaksanaan_click_cek_rubrik_observasi'
  | 'pelaksanaan_click_form_a'
  | 'pelaksanaan_click_form_b'
  | 'pelaksanaan_click_form_c'
  | 'pelaksanaan_click_form_d'
  | 'pelaksanaan_open_form_a'
  | 'pelaksanaan_open_form_b'
  | 'pelaksanaan_open_form_c'
  | 'pelaksanaan_open_form_d'
  | 'pelaksanaan_click_form_a_back_to_hub'
  | 'pelaksanaan_click_form_a_tujuan_formulir'
  | 'pelaksanaan_click_form_a_mengerti_tujuan_formulir'
  | 'pelaksanaan_click_form_a_cek_rubrik_observasi'
  | 'pelaksanaan_click_form_a_mengerti_rubrik_observasi'
  | 'pelaksanaan_click_form_a_simpan_draf'
  | 'pelaksanaan_click_form_a_konfirmasi_simpan_draf'
  | 'pelaksanaan_click_form_a_kumpulkan'
  | 'pelaksanaan_click_form_a_konfirmasi_kumpulkan'
  | 'pelaksanaan_click_form_a_cek_ulang'
  | 'pelaksanaan_click_form_b_cek_rubrik_observasi'
  | 'pelaksanaan_click_form_b_mengerti_rubrik_observasi'
  | 'pelaksanaan_click_form_c_lanjut'
  | 'pelaksanaan_click_form_c_tujuan_formulir'
  | 'pelaksanaan_click_form_c_back'
  | 'pelaksanaan_click_form_c_saya_mengerti_tujuan_formulir'
  | 'pelaksanaan_click_form_c_jawab_pertanyaan_1'
  | 'pelaksanaan_click_form_c_jawab_pertanyaan_2'
  | 'pelaksanaan_click_form_c_jawab_pertanyaan_3'
  | 'pelaksanaan_click_form_c_panduan_pengisian'
  | 'pelaksanaan_click_form_c_cek_penilaian_observasi'
  | 'pelaksanaan_click_form_c_cek_penilaian_observasi_back'
  | 'pelaksanaan_click_form_c_simpan_draf'
  | 'pelaksanaan_click_form_c_kumpulkan'
  | 'pelaksanaan_click_form_c_kumpulkan_popup'
  | 'pelaksanaan_click_form_c_cek_ulang_popup'
  | 'pelaksanaan_click_form_d_tujuan_formulir'
  | 'pelaksanaan_click_form_d_mengerti_tujuan_formulir'
  | 'pelaksanaan_click_form_d_kumpulkan'
  | 'pelaksanaan_click_form_d_konfirmasi_kumpulkan'
  | 'pelaksanaan_click_form_d_simpan_draf'
  | 'penilaian_ks_open_home'
  | 'penilaian_ks_home_click_cek_profil'
  | 'penilaian_ks_home_click_lakukan_penilaian'
  | 'penilaian_ks_home_click_pusat_bantuan'
  | 'penilaian_ks_home_click_selengkapnya'
  | 'penilaian_ks_open_hub'
  | 'penilaian_ks_hub_click_cek_profil'
  | 'penilaian_ks_hub_click_cek_daftar_guru'
  | 'penilaian_ks_hub_click_kumpulkan'
  | 'penilaian_ks_hub_click_cek'
  | 'penilaian_ks_hub_click_selengkapnya'
  | 'penilaian_ks_open_periksa_skp'
  | 'penilaian_ks_periksa_skp_type_teacher_name'
  | 'penilaian_ks_periksa_skp_click_navbar'
  | 'penilaian_ks_periksa_skp_click_nilai'
  | 'penilaian_ks_periksa_skp_click_cek'
  | 'penilaian_ks_open_rincian_penilaian'
  | 'penilaian_ks_rincian_penilaian_click_nilai'
  | 'penilaian_ks_rincian_penilaian_click_periksa'
  | 'penilaian_ks_rincian_click_cek_observasi_kelas'
  | 'penilaian_ks_open_kelengkapan_dokumen'
  | 'penilaian_ks_open_form_b'
  | 'penilaian_ks_form_b_click_simpan_draf'
  | 'penilaian_ks_form_b_click_kumpulkan'
  | 'penilaian_ks_open_form_c'
  | 'penilaian_ks_form_c_click_simpan_draf'
  | 'penilaian_ks_form_c_click_kumpulkan'
  | 'penilaian_ks_form_c_click_cek_penilaian_observasi'
  | 'pelaksanaan_click_navigation_profile'
  | 'penilaian_ks_rincian_penilaian_click_nilai_perilaku_kerja'
  | 'penilaian_ks_rincian_penilaian_click_lanjut_panduan_perilaku_kerja'
  | 'penilaian_ks_rincian_click_cek_kelengkapan_dokumen'
  | 'penilaian_ks_open_form_e3'
  | 'penilaian_ks_open_form_e3_click_kumpulkan'
  | 'penilaian_ks_open_form_e3_popup_click_kumpulkan'
  | 'penilaian_ks_open_form_e3_popup_click_kembali'
  | 'penilaian_ks_open_form_e3_click_simpan_draf'
  | 'penilaian_ks_rincian_penilaian_click_ubah_perilaku_kerja'
  | 'penilaian_ks_open_form_d'
  | 'penilaian_ks_form_d_click_simpan_draf'
  | 'penilaian_ks_form_d_click_kumpulkan'
  | 'penilaian_ks_kd_click_cek_pengembangan_kompetensi'
  | 'penilaian_ks_kd_click_cek_tugas_tambahan'
  | 'penilaian_ks_kd_click_cek_detail'
  | 'penilaian_ks_form_b_click_cetak'
  | 'penilaian_ks_form_b_click_cek_rpp'
  | 'penilaian_ks_form_c_click_tujuan_formulir'
  | 'penilaian_ks_home_click_accept_and_reassess'
  | 'penilaian_ks_home_click_reject_application'
  | 'penilaian_ks_home_click_reassess_continue'
  | 'penilaian_ks_home_click_reassess_back'
  | 'penilaian_ks_home_click_reassess_understand'
  | 'penilaian_ks_hub_click_cek_penilaian_kinerja'
  | 'penilaian_ks_hub_click_cek_dokumen'
  | 'penilaian_ks_hub_click_cek_penetapan'
  | 'penilaian_ks_hub_click_cek_tampilan_guru'
  | 'penilaian_ks_open_penilaian_kinerja'
  | 'penilaian_ks_rincian_click_periksa_kelengkapan_dokumen'
  | 'penilaian_ks_perilaku_click_panduan_menilai'
  | 'penilaian_ks_perilaku_click_popup_lanjut_panduan_menilai'
  | 'penilaian_ks_kdd_click_unggah'
  | 'penilaian_ks_kdd_click_edit'
  | 'penilaian_ks_kdd_click_cek_detail'
  | 'penilaian_ks_skp_click_kirim_data'
  | 'penilaian_ks_skp_click_simpan_draf'
  | 'penilaian_ks_skp_click_kirim_ke_dinas'
  | 'penilaian_ks_skp_click_popup_kembali'
  | 'penilaian_ks_skp_click_popup_lanjut'
  | 'penilaian_ks_skp_click_penetapan_kinerja'
  | 'penilaian_ks_open_penetapan_kinerja'
  | 'penilaian_ks_pk_click_mulai'
  | 'penilaian_ks_pk_click_panduan_penetapan'
  | 'penilaian_ks_pk_click_cek_riwayat_pengiriman'
  | 'penilaian_ks_pk_click_baca_peraturan'
  | 'penilaian_ks_pk_click_lanjut_penetapan'
  | 'penilaian_ks_pk_click_simpan_draf'
  | 'penilaian_ks_pk_click_kirim_ke_guru'
  | 'penilaian_ks_pk_click_popup_kembali'
  | 'penilaian_ks_pk_click_popup_kirim'
  | 'penilaian_ks_pk_click_cetak'
  | 'pusat_notifikasi_home_click_notifikasi'
  | 'click_cek_dokumen_evaluasi'
  | 'open_hasil_penilaian_kinerja'

export interface SKPPelaksanaanTrackerParams {
  activity_type: ActivityType
  event_trigger?: ScreenName
  screen_name: ScreenName
  attributes?: Record<string, any>
}

type PeriodTrackerParams = { period: number; year: number }

const useSKPPelaksanaanTrackEvent = (periodParams?: PeriodTrackerParams) => {
  const { SETrackEvent, SETransformAttrs } = useSatuEventTracker()
  const { periodYear, periodNo } = usePeriodContext()

  const resolvedPeriod: PeriodTrackerParams = !!periodParams
    ? periodParams
    : { period: periodNo, year: periodYear }

  const trackEvent = (params: SKPPelaksanaanTrackerParams) => {
    SETrackEvent({
      eventName: SATU_EVENT_NAME,
      eventParams: {
        ...params,
        event_trigger: params.event_trigger ?? params.screen_name,
        attributes: SETransformAttrs({
          ...resolvedPeriod,
          ...params?.attributes,
        }),
        event_trace_id: '',
      },
      sendUserData: true,
      sendDefaultParams: true,
      sendAttributes: false,
    })
  }

  return {
    trackEvent,
  }
}

export default useSKPPelaksanaanTrackEvent
