import { removeDuplicate } from 'utils/array'

export const sortAllArrayInObject = (obj: object) => {
  return Object?.keys(obj ?? {})?.reduce((acc, key) => {
    let childVal = obj[key]
    if (Array.isArray(childVal)) {
      childVal = removeDuplicate?.(childVal)?.sort()
    }
    if (childVal?.constructor?.name === 'Object') {
      childVal = sortAllArrayInObject(childVal)
    }
    acc[key] = childVal
    return acc
  }, {})
}

/**
 * Summarizes the values of two objects by adding the corresponding values of each key.
 *
 * @param obj1 - The first object to be summarized.
 * @param obj2 - The second object to be summarized.
 * @returns A new object with the summarized values.
 *
 * @example
 * const obj1 = { a: 1, b: 2, c: 3 }
 * const obj2 = { a: 4, b: 5, c: 6 }
 * const summary = summarizeObjects(obj1, obj2)
 * console.log(summary) // Output: { a: 5, b: 7, c: 9 }
 */
export const summarizeObjects = <T = { [key: string]: number }>(
  obj1: T,
  obj2: T
): T => {
  // Handle missing values
  if (!obj1 && !obj2) return {} as T

  // Handle missing values in obj1
  if (!obj1) return obj2

  // Handle missing values in obj2
  if (!obj2) return obj1

  const summary = { ...obj1 } // Create a new object to store the summarized values

  // Iterate over the keys of obj1
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      // Summarize the value of the key from obj1 and obj2
      const value1 = !!obj1?.[key] ? obj1?.[key] : 0
      const value2 = !!obj2?.[key] ? obj2?.[key] : 0
      const summarizedValue = (value1 as number) + (value2 as number)

      // Save the summarized value to the summary object
      summary[key] = summarizedValue as unknown as T[Extract<keyof T, string>]
    }
  }

  return summary
}
