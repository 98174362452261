import {
  api,
  useMutationApi,
  useQueryApi,
  type QueryApiConfig,
} from 'utils/api'
import usePeriodContext from 'app/KinerjaShared/hooks/usePeriodContext'

import type { MutationApiConfig } from 'utils/api'
import type { AxiosError } from 'axios'
import type { QueryKey, UseQueryResult } from 'react-query'

/**
 * Custom hook for making queries to the kinerja API that automatically includes the SKP year and period.
 *
 * @param path - The API endpoint path.
 * @param opts - Additional options for the query.
 * @returns The result of the query.
 */
export const useKinerjaApi = <TQueryFnData, TQueryFnError = AxiosError>(
  path: string,
  opts: QueryApiConfig<TQueryFnData> = {}
): UseQueryResult<TQueryFnData, TQueryFnError> => {
  const {
    isEnabled,
    isHavePeriod,
    periodYear: skpYear,
    periodNo: skpPeriod,
    isFetched,
  } = usePeriodContext()

  // Check if the options has a query key
  const hasQueryKey = !!opts.queryKey

  // If the options has a query key, use it. Otherwise, use the path as the query key
  const queryKey = opts.queryKey ?? path

  // If the queryKey is an array, use it as is. Otherwise, convert it to an array
  const queryKeys = Array.isArray(queryKey) ? queryKey : [queryKey]

  // If the options has no query key, add the data and params to the query key
  if (!hasQueryKey) {
    if (!!opts?.axiosConfig?.data) queryKeys.push(opts.axiosConfig.data)
    if (!!opts?.axiosConfig?.params) queryKeys.push(opts.axiosConfig.params)
  }

  // Add the SKP year and period to the query key
  const queryKeyFinal = [
    ...queryKeys,
    ...(!!skpYear ? [skpYear] : []),
    ...(!!skpPeriod ? [skpPeriod] : []),
  ]

  // Prepare the options
  const options: QueryApiConfig<TQueryFnData> = {
    ...opts,
    queryConfig: {
      ...opts.queryConfig,
      enabled: isFetched && opts.queryConfig?.enabled,
    },
    axiosConfig: {
      ...opts.axiosConfig,
      params: {
        ...(isEnabled && isHavePeriod && !!skpYear && !!skpPeriod
          ? { skp_year: skpYear, skp_period: skpPeriod }
          : {}),
        ...opts.axiosConfig?.params, // Placed on the bottom to allow overriding the SKP year and period
      },
    },
    queryKey: queryKeyFinal,
  }

  return useQueryApi(path, options)
}

/**
 * Custom hook for making mutation requests to the kinerja API that automatically includes the SKP year and period.
 *
 * @param path - The API endpoint path or a function that generates the path based on data.
 * @param opts - Additional options for the mutation.
 * @returns The result of the mutation.
 */
export const useKinerjaMutationApi = <TData, TError, TVariables>(
  path: string | ((data) => string),
  opts: MutationApiConfig<TData, TError, TVariables> = {}
) => {
  const {
    isEnabled,
    periodYear: skpYear,
    periodNo: skpPeriod,
  } = usePeriodContext()

  // Prepare the options
  const options = {
    ...opts,
    axiosConfig: {
      ...opts.axiosConfig,
      params: {
        ...(isEnabled && !!skpYear && !!skpPeriod
          ? { skp_year: skpYear, skp_period: skpPeriod }
          : {}),
        ...opts.axiosConfig?.params, // Placed on the bottom to allow overriding the SKP year and period
      },
    },
  }

  return useMutationApi(path, options)
}

export const useKinerjaMutationApiV2 = <TData, TError, TVariables>(
  path: string | ((data) => string),
  opts: MutationApiConfig<TData, TError, TVariables> = {}
) => {
  const { periodYear: skp_year, periodNo: skp_period } = usePeriodContext()

  const mutationFn = async (props: any): Promise<TData> => {
    if (opts.mutationFn) {
      return opts.mutationFn({
        ...props,
        skp_year,
        skp_period,
      })
    }

    if (!(path instanceof Function)) {
      return api(path, {
        ...opts.axiosConfig,
        data: {
          skp_year,
          skp_period,
          ...props,
        },
      })
    }
  }

  return useKinerjaMutationApi(path, {
    ...opts,
    mutationFn,
  })
}

export const useGetKinerjaQuerykeys = (queryKey: QueryKey) => {
  const {
    isEnabled,
    periodYear: skpYear,
    periodNo: skpPeriod,
  } = usePeriodContext()

  const queryKeys = Array.isArray(queryKey) ? queryKey : [queryKey]

  if (isEnabled) return [...queryKeys, skpYear, skpPeriod]
  return queryKeys
}
