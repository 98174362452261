import type { CommonResponse } from 'types/api'
import type {
  MetaGetListGuruStates,
  ResponseGetListGuruStates,
} from 'types/kinerja-pelaksanaan'

export const dummyListGuruStates: CommonResponse<
  ResponseGetListGuruStates,
  MetaGetListGuruStates
> = {
  data: [
    {
      userId: 'dummy-1',
      name: 'Sri Hariyati',
      class: 'Kelas 2',
      subject: 'Matematika',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: 'DOCUMENT_PREPARATION',
        progress: 0,
        total: 3,
        gradingState: 'Rating observasi',
      },
      // document: {
      //   state: 'Belum lengkap',
      //   progress: 0,
      //   total: 4,
      // },
      ptkId: 'dummy-1',
      workBehavior: {
        // state: 'NOT_CREATED',
        progress: 0,
        total: 1,
        // result: '',
        gradingState: 'Belum dinilai',
      },
      // performancePractice: {
      //   state: 'NOT_CREATED',
      //   result: '',
      // },
      // predicateRecommendation: '',
    },
    {
      userId: 'dummy-2',
      name: 'Rani Indrawati',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      // document: {
      //   state: 'Belum lengkap',
      //   progress: 0,
      //   total: 6,
      // },
      ptkId: 'dummy-2',
      workBehavior: {
        // state: 'NOT_CREATED',
        progress: 0,
        total: 1,
        // result: '',
        gradingState: 'Sudah dinilai',
      },
      // performancePractice: {
      //   state: 'NOT_CREATED',
      //   result: '',
      // },
      // predicateRecommendation: '',
    },
    {
      userId: 'dummy-3',
      name: 'Teguh Priyanto',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-3',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
    {
      userId: 'dummy-4',
      name: 'Wahyu Lukita',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-4',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
    {
      userId: 'dummy-5',
      name: 'Arina Ara',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-5',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
    {
      userId: 'dummy-6',
      name: 'Arip Wibowo',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-6',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
    {
      userId: 'dummy-7',
      name: 'Yunanda Suswanto',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-7',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
    {
      userId: 'dummy-8',
      name: 'Yolanda',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-8',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
    {
      userId: 'dummy-9',
      name: 'Denny Firmansyah',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-9',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
    {
      userId: 'dummy-10',
      name: 'Fahmi Saputra',
      class: '',
      subject: '',
      employeeStatus: 'PNS',
      observation: {
        date: 'Senin, 1 Maret 2024',
        state: '',
        progress: 0,
        total: 3,
        gradingState: 'Sudah selesai',
      },
      ptkId: 'dummy-10',
      workBehavior: {
        progress: 0,
        total: 1,
        gradingState: 'Sudah dinilai',
      },
    },
  ],
  meta: {
    total: 10,
    completed: 9,
    states: {
      'Nilai tindak lanjut': 0,
      'Nilai refleksi tindak lanjut': 0,
      'Rating observasi': 1,
      'Sudah selesai': 9,
    },
  },
}
