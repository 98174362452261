import { formatPeriod, monthDates } from 'app/KinerjaShared/utils/period'
import usePeriodContext from 'app/KinerjaShared/hooks/usePeriodContext'

import type { FC } from 'react'

export type CardPeriodProps = {
  period: number
  year?: number
  withDate?: boolean
  withYear?: boolean
  separator?: string

  // Enable multiple period support, this will use the period context to get the period data
  enableMultiplePeriodSupport?: boolean
}

/**
 * Renders a card displaying a period date range based on the given period and year.
 * Use this instead of `CardPeriodType` if you won't rely on the selected global period.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.period - The period number.
 * @param {number} [props.year=new Date().getFullYear()] - The year of the period.
 * @param {boolean} [props.withDate=false] - Whether to display the date along with the period.
 * @param {boolean} [props.withYear=true] - Whether to display the year along with the period.
 * @param {string} [props.separator=' - '] - The separator between the periods.
 * @param {boolean} [props.enableMultiplePeriodSupport=false] - Whether to enable support for multiple periods. If enabled, the period context will be used to get the period data.
 * @returns {FC} The rendered card period component.
 */
const CardPeriod: FC<CardPeriodProps> = ({
  period,
  year = new Date().getFullYear(),
  withDate = false,
  withYear = true,
  separator = ' - ',
  enableMultiplePeriodSupport = false,
}) => {
  const { getPeriod } = usePeriodContext()

  if (enableMultiplePeriodSupport) {
    const periodData = getPeriod({ periodYear: year, periodNo: period })

    if (!periodData) return <></>

    return (
      <>
        {formatPeriod(periodData.startDate, periodData.endDate, {
          separator,
          withDate,
          withYear,
        })}
      </>
    )
  }

  return <>{monthDates(period, year, withDate, withYear)?.join(separator)}</>
}

export default CardPeriod
