import type { TPerformancePracticeResult } from 'types/kinerja-pelaksanaan'

export const PERFORMANCE_PRACTICE_RESULT_TEXT: Record<
  TPerformancePracticeResult,
  string
> = {
  BELOW_EXPECTATION: 'Di Bawah Ekspektasi',
  MEET_EXPECTATION: 'Sesuai Ekspektasi',
  EXCEED_EXPECTATION: 'Di Atas Ekspektasi',
}
