export function roundWithPrecision(value: number, precision: number): number {
  let multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const random: (min: number, max: number) => number = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min

/**
 * Transforms a number into human-readable format with delimiters every three digits
 * @param {number} number     Number to be transformed
 * @param {string} delimiter  Delimiter character, choose between dots or commas
 *
 * @returns {string} Human-readable number representation with delimiters
 */
export function delimit(number: number, delimiter: '.' | ',' = '.'): string {
  const rgx = /\B(?=(\d{3})+(?!\d))/g

  let result = ''

  const integerPart = Math.trunc(number)
  const decimalPart = parseFloat((number % 1).toFixed(2))
  const decimalDelimiter = delimiter === '.' ? ',' : '.'

  result = result + integerPart.toString().replace(rgx, delimiter)

  if (decimalPart)
    result = result + decimalDelimiter + decimalPart.toString().split('.')[1]

  return result
}

/**
 * Helper function to render a number in a human-readable format
 * @param {number} num Number to be rendered
 * @param {number} decimals Trailing decimal places to be rendered
 * @param {number} step Multiplier between notations
 * @param {string[]} notations List of notations for the rendered string
 * @returns {string} Human-readable number with notation
 */
export function humanizeNumber(
  num: number,
  decimals: number = 0,
  step: number = 1000,
  notations: string[] = [],
  separator: string = ''
) {
  if (num === 0) return '0' + (notations.length > 0 ? ` ${notations[0]}` : '')

  const dm = decimals < 0 ? 0 : decimals

  const i = Math.floor(Math.log(Math.abs(num)) / Math.log(step))

  return (
    delimit(parseFloat((num / Math.pow(step, i)).toFixed(dm))) +
    separator +
    notations[i]
  ).trim()
}

/**
 * Formats bytes into most-appropriate decimal notation.
 *
 * @param {number} bytes    Number of bytes
 * @param {number} decimals Number of decimal places
 * @returns {string} Human-readable file size string
 *
 * @example
 * formatBytes(1248) // "1,2 KB"
 * formatBytes(1248, 2) // "1,25 KB"
 * formatBytes(1248, 2, '.') // 1.25 KB
 */
export function formatBytes(bytes: number, decimals: number = 2): string {
  const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  return humanizeNumber(Math.abs(bytes), decimals, 1024, sizes, ' ')
}
