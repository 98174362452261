import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

import { Text } from '@wartek-id/text'
import 'react-circular-progressbar/dist/styles.css'
import { Icon } from '@wartek-id/icon-v2'

export interface PropsCircularProgress {
  value?: number
  maxValue?: number
  percentage?: boolean
}

const hexToRgb = (hexString: string): string => {
  const r = parseInt(hexString.substring(1, 3), 16)
  const g = parseInt(hexString.substring(3, 5), 16)
  const b = parseInt(hexString.substring(5, 7), 16)

  return `rgb(${r}, ${g}, ${b})`
}

const colorProgress = hexToRgb('#0B5AE2')

const CircularProgress = ({
  value = 0,
  maxValue = 0,
  percentage = false,
}: PropsCircularProgress) => {
  const progressBarMinValue = -1
  const progressBarMaxValue = 100
  const progressBarCurrentValue = (progressBarMaxValue / maxValue) * value

  const displayedCurrentValue = percentage
    ? `${progressBarCurrentValue}%`
    : `${value}/${maxValue}`

  return (
    <CircularProgressbarWithChildren
      minValue={progressBarMinValue}
      maxValue={progressBarMaxValue}
      value={progressBarCurrentValue}
      styles={{
        path: {
          stroke: colorProgress,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          shapeRendering: 'geometricPrecision',
        },
      }}
    >
      <Text variant="heading-md" color="primary">
        {displayedCurrentValue}
      </Text>
    </CircularProgressbarWithChildren>
  )
}

export const CircularLock = () => {
  return (
    <CircularProgressbarWithChildren
      minValue={0}
      maxValue={100}
      value={1}
      styles={{
        path: {
          stroke: colorProgress,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          shapeRendering: 'geometricPrecision',
        },
      }}
    >
      <Icon>lock_outlined</Icon>
    </CircularProgressbarWithChildren>
  )
}

export default CircularProgress
