// Why?:
//   This hooks is only used in pages/_app.tsx, and the purpose is
//   to store user id from both legacy apps and newer apps.
//   The stored user id is being used all over assessment's related features
//   for event tracking.
// When to remove?:
//   Legacy app version that still uses access token has been deprecated.
import { useEffect } from 'react'

import AndroidHandler from 'utils/androidHandler'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_ID_STORAGE_KEY } from 'configs/auth'

export default function useTechDebtStoreUserId() {
  const androidHandler = AndroidHandler()
  const [userId, setUserId] = useLocalStorage(USER_ID_STORAGE_KEY, null)
  const androidUserId = androidHandler?.enabled
    ? androidHandler?.getUserId()
    : null

  useEffect(() => {
    setUserId(androidUserId)
  }, [androidUserId, userId, setUserId])
}
