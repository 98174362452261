import { UNPROTECTED_ROUTES } from 'configs/routes'

import type { UnprotectedRoute } from 'configs/routes'
import type { NextRouter } from 'next/router'

export const isBrowser = () => typeof window !== 'undefined'

export const isPathProtected = (router: NextRouter): boolean => {
  const url = new URL(window.location.href)

  return !UNPROTECTED_ROUTES.some((route: UnprotectedRoute) => {
    if (typeof route === 'string') return router.pathname === route

    const isPathMatched = router.pathname === route.pathname

    if (isPathMatched && !!route.query) {
      // Next router object can return empty query in initial time
      // even though the url should have query,
      // thus we need to get it from window.location.href.
      return !!url.searchParams.get(route.query)
    }

    return isPathMatched
  })
}
