import type { TPredicateRecommendationResult } from 'types/kinerja-pelaksanaan'

export const PREDICATE_RECOMMENDATION_RESULT_TEXT: Record<
  TPredicateRecommendationResult,
  string
> = {
  VERY_GOOD: 'Sangat Baik',
  GOOD: 'Baik',
  NEED_IMPROVEMENT: 'Butuh Perbaikan',
  BAD: 'Kurang',
  VERY_BAD: 'Sangat Kurang',
}

export const ORGANIZATION_PREDICATE_RECOMMENDATION_RESULT_TEXT: Record<
  TPredicateRecommendationResult,
  string
> = {
  VERY_GOOD: 'Istimewa',
  GOOD: 'Baik',
  NEED_IMPROVEMENT: 'Butuh Perbaikan',
  BAD: 'Kurang',
  VERY_BAD: 'Sangat Kurang',
}
