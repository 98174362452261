import React from 'react'

import { useAPIGetAllServiceToggle } from 'api/toggles/useAPIToggles'
import { LoadingState } from 'components/LoadingState'

interface ToggleFetcherProps extends React.HTMLProps<HTMLDivElement> {
  isEnabled: boolean
}

const ToggleFetcher = ({ children, isEnabled }: ToggleFetcherProps) => {
  const { isLoading } = useAPIGetAllServiceToggle(isEnabled)
  if (isLoading) {
    return <LoadingState />
  }
  return <div>{children}</div>
}

export default ToggleFetcher
