import { createContext, useContext } from 'react'

import type { SKPPeriodV2 } from 'types/kinerja'

interface PeriodContext {
  // for selected period
  isEnabled: boolean
  isHavePeriod: boolean
  isFetched: boolean
  periodYear?: number
  periodNo?: number
  period?: SKPPeriodV2
  setPeriod?: (period: Pick<SKPPeriodV2, 'id'>) => void
  isCurrentPeriod?: boolean // Will return true if the selected period is the current period, based on the startDate & endDate on the period

  // raw response
  periods?: SKPPeriodV2[]

  // Helpers
  getPeriod?: ({
    periodYear,
    periodNo,
  }: {
    periodYear: number
    periodNo: number
  }) => SKPPeriodV2
}

export const PeriodContext = createContext<PeriodContext>({
  isEnabled: false,
  isHavePeriod: false,
  // If we set it to false, it will break too many tests.
  isFetched: true,
})

export default function usePeriodContext() {
  const context = useContext(PeriodContext)

  if (!context)
    throw new Error('usePeriodContext must be used within a PeriodContext')

  return context
}
