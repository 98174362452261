import type { CommonResponse } from 'types/api'
import type { IFollowUpTargetItemWithModules } from 'types/kinerja-pelaksanaan'

export const dummyFollowUpTargets: CommonResponse<
  IFollowUpTargetItemWithModules[]
> = {
  data: [
    {
      id: 'id-target-1',
      indicator: 'Pembelajaran efektif yang berpusat pada peserta didik',
      subIndicator:
        'Instruksi pembelajaran yang mencakup strategi dan komunikasi untuk menumbuhkan minat dan nalar kritis peserta didik',
      learningModules: [
        {
          id: 'id-lm-1',
          title: 'Ide-Ide Praktis Penggunaan Alat Matematika',
          url: '/pelatihan-mandiri/modul/168?from=topik&menuId=44&topik=44&topik_name=Numerasi%3A%20Ide-Ide%20Praktis%20Pembelajaran%20%5BKemitraan%20Dengan%20Inovasi%5D',
        },
        {
          id: 'id-lm-2',
          title: 'Permainan Kaya Numerasi',
          url: '/pelatihan-mandiri/modul/169?from=topik&menuId=44&topik=44&topik_name=Numerasi%3A%20Ide-Ide%20Praktis%20Pembelajaran%20%5BKemitraan%20Dengan%20Inovasi%5D',
        },
        {
          id: 'id-lm-3',
          title: 'Kreativitas dan Inovasi',
          url: '/pelatihan-mandiri/modul/85?from=topik&menuId=20&topik=20&topik_name=Semangat%20Guru%202%3A%20Kompetensi%20Nonteknis%20Kurikulum%20Merdeka%20%5BKemitraan%20dengan%20HP%20Indonesia%20dan%20V%26V%20Communications%5D',
        },
      ],
    },
    {
      id: 'id-target-2',
      indicator: 'Pembelajaran efektif yang berpusat pada peserta didik',
      subIndicator:
        'Latar belakang sosial, budaya, agama dan ekonomi yang relevan dengan kebutuhan belajar peserta didik',
      learningModules: [
        {
          id: 'id-lm-4',
          title: 'Dunia yang Berwarna',
          url: '/pelatihan-mandiri/modul/265?from=topik&menuId=82&topik=82&topik_name=Wawasan%20Kebinekaan%20Global%20%28PAUD%29%20%5BKemitraan%20dengan%20PUSPEKA%5D',
        },
        {
          id: 'id-lm-5',
          title: 'Negeri Penuh Harmoni',
          url: '/pelatihan-mandiri/modul/261?from=topik&menuId=82&topik=82&topik_name=Wawasan%20Kebinekaan%20Global%20%28PAUD%29%20%5BKemitraan%20dengan%20PUSPEKA%5D',
        },
        {
          id: 'id-lm-6',
          title: 'Sekolahku yang Bineka',
          url: '/pelatihan-mandiri/modul/263?from=topik&menuId=82&topik=82&topik_name=Wawasan%20Kebinekaan%20Global%20%28PAUD%29%20%5BKemitraan%20dengan%20PUSPEKA%5D',
        },
      ],
    },
    {
      id: 'id-target-3',
      indicator: 'Pembelajaran efektif yang berpusat pada peserta didik',
      subIndicator:
        'Desain pembelajaran yang relevan dengan kondisi di sekitar sekolah dengan melibatkan peserta didik',
      learningModules: [
        {
          id: 'id-lm-7',
          title: 'Mendesain Projek Penguatan Profil Pelajar Pancasila',
          url: '/pelatihan-mandiri/modul/229?from=topik&menuId=67&topik=67&topik_name=Projek%20Penguatan%20Profil%20Pelajar%20Pancasila%3A%20Contoh%20Praktik',
        },
        {
          id: 'id-lm-8',
          title: 'Layanan Peminatan dan Perencanaan Individual',
          url: '/pelatihan-mandiri/modul/207?from=topik&menuId=62&topik=62&topik_name=Bimbingan%20dan%20Konseling%3A%20Layanan%20Peminatan%20dan%20Responsif',
        },
        {
          id: 'id-lm-9',
          title: 'Modul 1 Pendidikan Inklusif Berbasis Hak',
          url: '/pelatihan-mandiri/modul/253?from=topik&menuId=76&topik=76&topik_name=Pendidikan%20Inklusif%20%5BKemitraan%20dengan%20UNICEF%5D',
        },
      ],
    },
  ],
  meta: {},
}
