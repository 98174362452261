import create from 'zustand'
import { persist } from 'zustand/middleware'

import { devtools } from 'utils/zustand'
import { removeDuplicate } from 'utils/array'
import { sortAllArrayInObject } from 'utils/object'

import type { State } from 'zustand'
import type { TToolkitFilters } from 'types/filters'
import type { TFase } from 'types/classAndPhases'

export type TFiltersStore = State & {
  filters?: TToolkitFilters
  isLoading?: boolean
  phaseClassList?: Record<string, string[]>
  subjects?: string[]
  phaseSubjectList?: Record<string, string[]>
  subjectPhaseDomainsList?: Record<string, Record<TFase, string[]>>
  setFilters: (filters: TToolkitFilters) => void
  setIsLoading: (isLoading: boolean) => void
  getDomainsBySubjectAndPhase: (subject: string, phase: TFase[]) => string[]
}

const initialState: Partial<TFiltersStore> = {
  filters: null,
  isLoading: false,
  phaseClassList: null,
  subjects: null,
  phaseSubjectList: null,
  subjectPhaseDomainsList: null,
}

export const useToolkitFilterStore = create<TFiltersStore>(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setFilters: (filters: TToolkitFilters) =>
          set({
            filters: filters ?? initialState.filters,
            phaseClassList: filters?.phase_class_lists
              ? {
                  Fondasi: ['PAUD'],
                  ...filters?.phase_class_lists,
                }
              : initialState.phaseClassList,
            subjects: filters?.subjects
              ? removeDuplicate(filters?.subjects)?.sort()
              : initialState?.subjects,
            phaseSubjectList:
              Object.keys(filters?.phase_subject_lists)?.reduce((acc, key) => {
                return {
                  ...acc,
                  [key]: removeDuplicate(
                    filters?.phase_subject_lists?.[key]
                  )?.sort(),
                }
              }, {}) ?? initialState?.phaseSubjectList,
            subjectPhaseDomainsList: sortAllArrayInObject(
              filters?.subject_phase_domain_lists
            ),
          }),
        getDomainsBySubjectAndPhase: (subject, phase) => {
          const subjectPhaseDomainsList = get().subjectPhaseDomainsList
          return Object.keys(subjectPhaseDomainsList?.[subject])?.reduce(
            (acc, p: TFase) => [
              ...acc,
              ...(phase?.includes(p)
                ? subjectPhaseDomainsList?.[subject]?.[p]
                : []),
            ],
            []
          )
        },
        setIsLoading: (isLoading: boolean) =>
          set({
            isLoading: isLoading ?? initialState?.isLoading,
          }),
      }),
      {
        name: 'toolkit-filters',
        partialize: ({
          filters,
          subjects,
          phaseSubjectList,
          phaseClassList,
          subjectPhaseDomainsList,
        }) => ({
          filters,
          subjects,
          phaseSubjectList,
          phaseClassList,
          subjectPhaseDomainsList,
        }),
      }
    )
  )
)
