export const parseQuery = <T = string>(
  query: T extends (infer U)[]
    ? (U | string) | (U | string)[]
    : (T | string) | (T | string)[],
  into: 'string' | 'array' = 'string'
): T => {
  if (!query) return null
  if (into === 'string') return Array.isArray(query) ? query?.[0] : query
  return (Array.isArray(query) ? query : [query]) as T
}

export const getRouterBasePath = (path: string) => {
  if (!path) return null
  if (typeof path !== 'string') return path
  return path.replace(/^\/([^?\/]*).*$/, '$1')
}
/**
 * Returns true if the path is matched with the route pattern. Otherwise, return false
 *
 * E.g. `isPathMatchRoute("/a/b/:param", "/a/b/111")` will resolves to true
 *
 * @param route Route pattern to be matched
 * @param path Path url to be matched
 *
 * @returns boolean
 */
export const isPathMatchRoute = (path: string, route: string): boolean => {
  const splittedRoute = route.split('/')
  const splittedPath = path.split('/')

  if (splittedPath.length != splittedRoute.length) return false

  const isMatched = splittedRoute.every((section, i) => {
    if (section.startsWith(':')) {
      return !!splittedPath[i]
    } else {
      return section === splittedPath[i]
    }
  })

  return isMatched
}
