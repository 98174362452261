import type {
  TWorkBehaviorImpactScore,
  TWorkBehaviorImplementationScore,
  TWorkBehaviorResult,
} from 'types/kinerja-pelaksanaan'

export const WORK_BEHAVIOR_RESULT_TEXT: Record<TWorkBehaviorResult, string> = {
  BELOW_EXPECTATION: 'Di Bawah Ekspektasi',
  MEET_EXPECTATION: 'Sesuai Ekspektasi',
  EXCEED_EXPECTATION: 'Di Atas Ekspektasi',
}

export const WORK_BEHAVIOR_IMPLEMENTATION_OPTIONS: Array<{
  value: TWorkBehaviorImplementationScore
  label: string
}> = [
  { value: 1, label: 'Tidak melakukan upaya mempelajari' },
  { value: 2, label: 'Ada upaya tapi kesulitan mempelajari' },
  { value: 3, label: 'Menunjukkan ada perubahan perilaku' },
  { value: 4, label: 'Menunjukkan perilaku nyata tapi belum konsisten' },
  { value: 5, label: 'Menunjukkan perilaku nyata dan konsisten' },
]

export const WORK_BEHAVIOR_IMPACT_OPTIONS: Array<{
  value: TWorkBehaviorImpactScore
  label: string
}> = [
  { value: 1, label: 'Terlihat upayanya tapi tidak berdampak' },
  { value: 2, label: 'Terlihat upayanya yang berpotensi berdampak' },
  { value: 3, label: 'Berdampak kecil pada kinerjanya atau timnya' },
  { value: 4, label: 'Berdampak besar pada kinerjanya atau timnya' },
  { value: 5, label: 'Berdampak pada Kinerja Satuan Pendidikan' },
]
