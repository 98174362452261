import { type UseQueryOptions } from 'react-query'

import { useQueryApi } from 'utils/api'

import type { IUserGroups } from 'types/user'

const CACHE_TIME = 60 * 60 * 1000 // 1 hour in milliseconds

export function useAPIGetUserGroupsByUserId(
  id: string,
  opts?: UseQueryOptions<IUserGroups>
) {
  return useQueryApi<IUserGroups>('/guru/teachers/v1alpha2/groups', {
    axiosConfig: {
      params: {
        user_id: id,
        limit: 50,
      },
    },
    queryConfig: {
      retry: false,
      useErrorBoundary: false,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
      ...opts,
    },
  })
}
