import type { CommonResponse } from 'types/api'
import type {
  ResponseGetObservationImplementation,
  TStateObservationImplementation,
} from 'types/kinerja-pelaksanaan'

export const dummyObservationImplementation: CommonResponse<ResponseGetObservationImplementation> =
  {
    data: {
      state: '' as TStateObservationImplementation,
      detail: {
        behavioralTargets: [],
        superiorRecommendation: '',
      },
    },
    meta: {},
  }
