import { dummyDocumentPreparation } from './dummy-data/documentPreparation'
import { dummyEvaluationDetail } from './dummy-data/evaluationDetail'
import { dummyKinerjaProfile } from './dummy-data/kinerjaProfile'
import { dummyListGuruStates } from './dummy-data/listGuruStates'
import { dummyObservationImplementation } from './dummy-data/observationImplementation'
import { dummyPelaksanaanProgress } from './dummy-data/pelaksanaanProgress'
import { dummySupportingDocuments } from './dummy-data/supportingDocuments'
import { dummyTargetPerilaku } from './dummy-data/targetPerilaku'
import { dummyFollowUpDocument } from './dummy-data/followUpDocument'
import { dummyFollowUpEvaluationDocument } from './dummy-data/followUpEvaluationDocument'
import { dummyFollowUpReflectionDocument } from './dummy-data/followUpReflectionDocument'
import { dummyFollowUpTargets } from './dummy-data/followUpTargets'
import { dummyFollowUpReflectionEvaluationDocument } from './dummy-data/followUpReflectionEvaluationDocument'
import { dummyWorkBehavior } from './dummy-data/workBehavior'
import { dummyPenetapanPredikatKinerjaStep } from './dummy-data/penetapanPredikatKinerjaStep'
import { dummyPredikatKinerjaList } from './dummy-data/predikatKinerjaList'
import { PENGELOLAAN_KINERJA_KS_PENILAI_PATH } from 'app/KinerjaShared/constants/path'

import type { ResponseKinerjaProfil } from 'types/kinerja'
import type { CommonResponse } from 'types/api'
import type {
  IFollowUpTargetItemWithModules,
  ParamsUpsertFollowUpEvaluationDocument,
  ParamsUpsertFollowUpReflectionEvaluationDocument,
  ParamsUpsertObservationImplementation,
  ParamsUpsertPredikatKinerjaList,
  ParamsUpsertWorkBehavior,
  ResponseEvaluationDetail,
  ResponseFollowUpDocument,
  ResponseFollowUpEvaluationDocument,
  ResponseFollowUpReflectionDocument,
  ResponseFollowUpReflectionEvaluationDocument,
  ResponseGetListGuruStates,
  ResponseGetObservationImplementation,
  ResponseGetPenetapanPredikatKinerjaStep,
  ResponseGetPredikatKinerjaList,
  ResponsePelaksanaanDocumentPreparation,
  ResponsePelaksanaanProgress,
  ResponsePelaksanaanTargetPerilaku,
  ResponseSupportingDocument,
  ResponseWorkBehavior,
  TPerformancePracticeResult,
  TPredicateRecommendationResult,
  TWorkBehaviorResult,
} from 'types/kinerja-pelaksanaan'

interface DummyPengelolaanKinerja {
  listGuruStates: CommonResponse<ResponseGetListGuruStates>
  supportingDocuments: CommonResponse<ResponseSupportingDocument>
  evaluationDetail: CommonResponse<ResponseEvaluationDetail>
  pelaksanaanProgress: CommonResponse<ResponsePelaksanaanProgress>
  kinerjaProfile: ResponseKinerjaProfil
  targetPerilaku: CommonResponse<ResponsePelaksanaanTargetPerilaku[]>
  documentPreparation: CommonResponse<ResponsePelaksanaanDocumentPreparation>
  observationImplementation: CommonResponse<ResponseGetObservationImplementation>
  followUpDocument: CommonResponse<ResponseFollowUpDocument>
  followUpTargets: CommonResponse<IFollowUpTargetItemWithModules[]>
  followUpEvaluationDocument: CommonResponse<ResponseFollowUpEvaluationDocument>
  followUpReflectionDocument: CommonResponse<ResponseFollowUpReflectionDocument>
  followUpReflectionEvaluationDocument: CommonResponse<ResponseFollowUpReflectionEvaluationDocument>
  workBehavior: CommonResponse<ResponseWorkBehavior>
  penetapanPredikatKinerjaStep: ResponseGetPenetapanPredikatKinerjaStep
  predikatKinerjaList: ResponseGetPredikatKinerjaList
}

const sessionStorageKey = 'DEMO_PENGELOLAAN_KINERJA'

const initialData: DummyPengelolaanKinerja = {
  listGuruStates: dummyListGuruStates,
  supportingDocuments: dummySupportingDocuments,
  evaluationDetail: dummyEvaluationDetail,
  pelaksanaanProgress: dummyPelaksanaanProgress,
  kinerjaProfile: dummyKinerjaProfile,
  targetPerilaku: dummyTargetPerilaku,
  documentPreparation: dummyDocumentPreparation,
  observationImplementation: dummyObservationImplementation,
  followUpDocument: dummyFollowUpDocument,
  followUpTargets: dummyFollowUpTargets,
  followUpEvaluationDocument: dummyFollowUpEvaluationDocument,
  followUpReflectionDocument: dummyFollowUpReflectionDocument,
  followUpReflectionEvaluationDocument:
    dummyFollowUpReflectionEvaluationDocument,
  workBehavior: dummyWorkBehavior,
  penetapanPredikatKinerjaStep: dummyPenetapanPredikatKinerjaStep,
  predikatKinerjaList: dummyPredikatKinerjaList,
}

export const reinitialize = () => {
  window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(initialData))
}

export const getDummyData = (): DummyPengelolaanKinerja => {
  const stringData = window.sessionStorage.getItem(sessionStorageKey)

  if (!stringData) {
    reinitialize()
  }

  return JSON.parse(window.sessionStorage.getItem(sessionStorageKey))
}

export const removeDummyDemoData = () => {
  window.sessionStorage.removeItem(sessionStorageKey)
}

export let removeHandlers: (() => void) | undefined

const addBeforeUnloadHandler = () => {
  if (!removeHandlers) {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (
        window.location.pathname.includes(
          PENGELOLAAN_KINERJA_KS_PENILAI_PATH
        ) &&
        window.location.pathname.includes('/demo')
      ) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    removeHandlers = () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }

    window.addEventListener('beforeunload', beforeUnloadHandler)
  }
}

export const setDummyData = (key: keyof DummyPengelolaanKinerja, value) => {
  const currentData = getDummyData()
  window.sessionStorage.setItem(
    sessionStorageKey,
    JSON.stringify({ ...currentData, [key]: value })
  )

  addBeforeUnloadHandler()
}

export const getListGuruStates = () => getDummyData().listGuruStates
export const getSupportingDocuments = () => getDummyData().supportingDocuments
export const getEvaluationDetail = () => getDummyData().evaluationDetail
export const getPelaksanaanProgress = () => getDummyData().pelaksanaanProgress
export const getKinerjaProfile = () => getDummyData().kinerjaProfile

export const getTargetPerilaku = () => getDummyData().targetPerilaku
export const getDocumentPreparation = () => getDummyData().documentPreparation
export const getObservationImplementation = () =>
  getDummyData().observationImplementation
export const upsertObservationImplementation = (
  params: ParamsUpsertObservationImplementation
) =>
  new Promise((resolve) => {
    setDummyData('observationImplementation', {
      data: params,
      meta: {},
    })

    if (params.state === 'SUBMITTED') {
      const currentPelaksanaanProgress = getPelaksanaanProgress()
      const currentEvaluationDetail = getEvaluationDetail()

      setDummyData('pelaksanaanProgress', {
        ...currentPelaksanaanProgress,
        data: {
          ...currentPelaksanaanProgress.data,
          progressPercentage: 50,
          state: 'FOLLOW_UP_EVALUATION',
        },
      })

      setDummyData('evaluationDetail', {
        ...currentEvaluationDetail,
        data: {
          ...currentEvaluationDetail.data,
          observation: {
            ...currentEvaluationDetail.data.observation,
            progress: 1,
            state: 'FOLLOW_UP_EVALUATION',
          },
        },
      })
    }

    resolve(getDummyData())
  })

export const getFollowUpTargets = () => getDummyData().followUpTargets
export const getFollowUpDocument = () => getDummyData().followUpDocument
export const getFollowUpEvaluationDocument = () =>
  getDummyData().followUpEvaluationDocument
export const getFollowUpReflectionDocument = () =>
  getDummyData().followUpReflectionDocument
export const getFollowUpReflectionEvaluationDocument = () =>
  getDummyData().followUpReflectionEvaluationDocument

export const upsertFollowUpEvaluationDocument = (
  params: ParamsUpsertFollowUpEvaluationDocument
) =>
  new Promise((resolve) => {
    setDummyData('followUpEvaluationDocument', {
      data: params,
      meta: {},
    })

    if (params.state === 'SUBMITTED') {
      const currentPelaksanaanProgress = getPelaksanaanProgress()
      const currentEvaluationDetail = getEvaluationDetail()

      setDummyData('pelaksanaanProgress', {
        ...currentPelaksanaanProgress,
        data: {
          ...currentPelaksanaanProgress.data,
          progressPercentage: 75,
          state: 'REFLECTION_FOLLOW_UP_EVALUATION',
          latestFormState: 'NOT_CREATED',
        },
      })

      setDummyData('evaluationDetail', {
        ...currentEvaluationDetail,
        data: {
          ...currentEvaluationDetail.data,
          observation: {
            ...currentEvaluationDetail.data.observation,
            progress: 2,
          },
        },
      })
    }

    resolve(getDummyData())
  })

export const upsertFollowUpReflectionEvaluationDocument = (
  params: ParamsUpsertFollowUpReflectionEvaluationDocument
) =>
  new Promise((resolve) => {
    setDummyData('followUpReflectionEvaluationDocument', {
      data: params,
      meta: {},
    })

    if (params.state === 'SUBMITTED') {
      const currentPelaksanaanProgress = getPelaksanaanProgress()
      const currentEvaluationDetail = getEvaluationDetail()

      setDummyData('pelaksanaanProgress', {
        ...currentPelaksanaanProgress,
        data: {
          ...currentPelaksanaanProgress.data,
          progressPercentage: 100,
          state: 'COMPLETED',
        },
      })

      setDummyData('evaluationDetail', {
        ...currentEvaluationDetail,
        data: {
          ...currentEvaluationDetail.data,
          observation: {
            ...currentEvaluationDetail.data.observation,
            progress: 3,
          },
          performancePractice: {
            ...currentEvaluationDetail.data.performancePractice,
            result: 'MEET_EXPECTATION',
          },
        },
      })

      const { data: listGuru, meta: metaListGuru } = getListGuruStates()
      listGuru[0] = {
        ...listGuru[0],
        observation: {
          ...listGuru[0].observation,
          progress: 3,
          gradingState: 'Sudah selesai',
        },
        workBehavior: {
          ...listGuru[0].workBehavior,
          progress: 1,
          gradingState: 'Sudah dinilai',
        },
      }

      setDummyData('listGuruStates', {
        data: listGuru,
        meta: {
          ...metaListGuru,
          completed: 10,
          states: {
            ...metaListGuru.states,
            'Sudah selesei': 10,
            'Rating observasi': 0,
          },
        },
      })
    }

    resolve(getDummyData())
  })

export const getworkBehavior = () => getDummyData().workBehavior

export const upsertWorkBehavior = (params: ParamsUpsertWorkBehavior) =>
  new Promise((resolve) => {
    const currentWorkBehavior = getworkBehavior()

    const newWorkBehavior: CommonResponse<ResponseWorkBehavior> = {
      ...currentWorkBehavior,
      data: {
        ...params,
        detail: {
          aspects: currentWorkBehavior.data.detail.aspects.map((currAspect) => {
            const newAspect = params.detail.aspects.find(
              (newAspect) => newAspect.aspectId === currAspect.aspectId
            )
            const impact = newAspect.impact
            const implementation = newAspect.implementation

            return {
              ...currAspect,
              impact,
              implementation,
            }
          }),
        },
      },
    }

    setDummyData('workBehavior', newWorkBehavior)

    const currentEvaluationDetail = getEvaluationDetail()

    setDummyData('evaluationDetail', {
      ...currentEvaluationDetail,
      data: {
        ...currentEvaluationDetail.data,
        workBehavior: {
          ...currentEvaluationDetail.data.workBehavior,
          ...(params.state === 'SUBMITTED'
            ? {
                progress:
                  currentEvaluationDetail.data.workBehavior.progress + 1,
                result: 'EXCEED_EXPECTATION',
              }
            : {}),
          state: params.state,
        },
      },
    })

    resolve(getDummyData())
  })

export const getPenetapanPredikatKinerjaStep = () =>
  getDummyData().penetapanPredikatKinerjaStep

export const getPredikatKinerjaList = () => getDummyData().predikatKinerjaList

export const upsertPredikatKinerjaList = (
  params: ParamsUpsertPredikatKinerjaList
) =>
  new Promise((resolve) => {
    const currentPenetapanPredikatKinerjaStep =
      getPenetapanPredikatKinerjaStep()
    const currentPredikatKinerjaList = getPredikatKinerjaList()

    let submissionStep = params.submissionStep

    if (params.state === 'SUBMITTED') {
      if (params.submissionStep === 'SUBMIT_KADIS') {
        submissionStep = 'SUBMIT_GURU'
      } else if (params.submissionStep === 'SUBMIT_GURU') {
        submissionStep = 'SUBMIT_BKN'
      }
    }

    setDummyData('penetapanPredikatKinerjaStep', {
      ...currentPenetapanPredikatKinerjaStep,
      data: {
        submissionStep,
      },
    })

    const generatePredikatKinerja = (
      praktikKinerjaRating: TPerformancePracticeResult,
      perilakuKerjaRating: TWorkBehaviorResult
    ): TPredicateRecommendationResult | undefined => {
      const dictionary: Record<string, TPredicateRecommendationResult> = {
        'BELOW_EXPECTATION-BELOW_EXPECTATION': 'VERY_BAD',
        'BELOW_EXPECTATION-MEET_EXPECTATION': 'NEED_IMPROVEMENT',
        'BELOW_EXPECTATION-EXCEED_EXPECTATION': 'NEED_IMPROVEMENT',
        'MEET_EXPECTATION-BELOW_EXPECTATION': 'BAD',
        'MEET_EXPECTATION-MEET_EXPECTATION': 'GOOD',
        'MEET_EXPECTATION-EXCEED_EXPECTATION': 'GOOD',
        'EXCEED_EXPECTATION-BELOW_EXPECTATION': 'BAD',
        'EXCEED_EXPECTATION-MEET_EXPECTATION': 'GOOD',
        'EXCEED_EXPECTATION-EXCEED_EXPECTATION': 'VERY_GOOD',
      }

      return dictionary[`${praktikKinerjaRating}-${perilakuKerjaRating}`]
    }

    const newPredikatKinerjaList: ResponseGetPredikatKinerjaList = {
      ...currentPredikatKinerjaList,
      data: {
        ...currentPredikatKinerjaList.data,
        completedUser: params.data.map((data, index) => ({
          ...currentPredikatKinerjaList.data.completedUser[index],
          workBehavior: {
            ...currentPredikatKinerjaList.data.completedUser[index]
              .workBehavior,
            result: data.workBehavior,
          },
          performancePractice: {
            ...currentPredikatKinerjaList.data.completedUser[index]
              .performancePractice,
            result: data.performancePractice,
          },
          predicateRecommendation: generatePredikatKinerja(
            data.performancePractice,
            data.workBehavior
          ),
        })),
      },
    }

    setDummyData('predikatKinerjaList', newPredikatKinerjaList)

    resolve(getDummyData())
  })
