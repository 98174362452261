import React from 'react'
import Script from 'next/script'

export const GoogleAnalytics = () => {
  if (typeof gtag !== 'undefined') {
    return null
  }

  return (
    <>
      {process.env.NEXT_PUBLIC_GA_ENABLED === 'true' && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
        />
      )}

      {process.env.NEXT_PUBLIC_GTAG_ENABLED === 'true' && (
        <Script
          id="google-analytics-gtag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTAG_ID}');
                `,
          }}
        />
      )}

      {Boolean(process.env.NEXT_PUBLIC_GA_ID ?? '') && (
        <Script
          id="google-analytics-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          window.gtag = gtag;
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
            page_path: window.location.pathname,
            transport_type: 'beacon'
          });
        `,
          }}
        />
      )}
    </>
  )
}
