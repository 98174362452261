import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ErrorBoundary } from 'react-error-boundary'

import { historyUtils } from '@wartek-id/fe-toolbox'

import { ROUTE_PATH_NAMES } from 'configs/routes'
import AndroidHandler from 'utils/androidHandler'
import { useAuth, useAuthProvider } from 'utils/auth'
import {
  SessionError,
  InternalServerError,
  ForbiddenError,
  CustomError,
  ServiceUnavailableError,
  NotFoundError,
  NetworkError,
} from 'utils/customError'
import { getRouterBasePath } from 'utils/router'
import { LayoutWithHeader as Layout } from 'components/Layout'
import {
  EmptyStateAction,
  EmptyStateImage,
  EmptyStateWithIllustration,
} from 'components/EmptyState'
import ImgErrorNoConnection from 'public/images/errors/img-no-connection.svg'
import ImgErrorMaintenance from 'public/images/errors/img-maintenance.svg'

import type { FallbackProps } from 'react-error-boundary'

interface CustomErrorBoundaryProps {
  children: React.ReactNode
  resetKeys: string[]
}

export default function CustomErrorBoundary({
  resetKeys,
  children,
}: CustomErrorBoundaryProps) {
  return (
    <ErrorBoundary
      resetKeys={resetKeys}
      FallbackComponent={CustomErrorFallback}
    >
      {children}
    </ErrorBoundary>
  )
}

export function CustomErrorFallback({
  error,
  resetErrorBoundary,
  onAction,
}: FallbackProps & { onAction?: () => void }) {
  const androidHandler = AndroidHandler()
  const router = useRouter()
  const { login, status } = useAuthProvider(false)

  React.useEffect(() => {
    if (status === 'success') {
      resetErrorBoundary()
      window.location.reload()
    }
  }, [status, resetErrorBoundary])

  if (error instanceof CustomError === false) {
    throw error
  }

  // @ts-expect-error error is not an Error object
  const { title, description } = error

  const { session } = useAuth()
  const basePath = getRouterBasePath(router.asPath)
  const pathName = ROUTE_PATH_NAMES?.[basePath] ?? 'Beranda'
  const fallbackBasePath = Boolean(session) ? '/home' : '/'
  const notFoundErrorBasePath = Object.keys(ROUTE_PATH_NAMES).includes(basePath)
    ? basePath
    : fallbackBasePath

  const notFoundErrorBackHandler = () => {
    if (pathName === 'Beranda') {
      androidHandler.backToHomepage(() =>
        router.push(`/${notFoundErrorBasePath}`)
      )
      return
    }

    // Handle pathName other than "Beranda"
    if (androidHandler.enabled) {
      window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL}/${notFoundErrorBasePath}`
    } else {
      router.push(`/${notFoundErrorBasePath}`)
    }
  }

  return (
    <Layout
      title=""
      onClickBack={() =>
        historyUtils.backToApp(() => {
          resetErrorBoundary()
          router.back()
        })
      }
    >
      <Head>
        <title>{title}</title>
      </Head>
      <EmptyStateWithIllustration title={title} description={description}>
        {error instanceof SessionError && (
          <>
            <EmptyStateImage
              data-testid="error-boundary-image"
              imageSrc="/images/errors/img-session-expired.png"
            />
            <EmptyStateAction
              data-testid="error-boundary-action-primary"
              color="black"
              onClick={() => androidHandler.backToHomepage(login)}
            >
              {androidHandler.enabled ? 'Kembali ke Beranda' : 'Login'}
            </EmptyStateAction>
          </>
        )}
        {error instanceof ForbiddenError && (
          <>
            <EmptyStateImage
              data-testid="error-boundary-image"
              imageSrc="/images/errors/img-no-access.png"
            />
            <EmptyStateAction
              data-testid="error-boundary-action-primary"
              color="black"
              onClick={() => androidHandler.backToHomepage(login)}
            >
              {androidHandler.enabled
                ? 'Kembali ke Beranda'
                : 'Coba Masuk Lagi'}
            </EmptyStateAction>
            <EmptyStateAction
              data-testid="error-boundary-action-secondary"
              color="white"
              onClick={() =>
                (window.location.href =
                  'https://pusatinformasi.guru.kemdikbud.go.id/hc/en-us/articles/10078077553817-Tampilan-Error-di-Platform-Merdeka-Mengajar-Versi-Web')
              }
            >
              Hubungi Bantuan
            </EmptyStateAction>
          </>
        )}
        {error instanceof NotFoundError && (
          <>
            <EmptyStateImage
              data-testid="error-boundary-image"
              imageSrc="/images/errors/img-not-found.png"
            />
            <EmptyStateAction
              data-testid="error-boundary-action-primary"
              color="black"
              onClick={notFoundErrorBackHandler}
            >
              Kembali ke {pathName}
            </EmptyStateAction>
          </>
        )}
        {(error instanceof InternalServerError ||
          error instanceof ServiceUnavailableError) && (
          <>
            <ImgErrorMaintenance
              data-testid="error-boundary-image"
              as="EmptyStateImageSvg"
            />
            <EmptyStateAction
              data-testid="error-boundary-action-primary"
              color="black"
              onClick={() => router.reload()}
            >
              Perbarui Halaman
            </EmptyStateAction>
            <EmptyStateAction
              data-testid="error-boundary-action-secondary"
              color="white"
              onClick={() => androidHandler.backToHomepage(router.back)}
            >
              Kembali
            </EmptyStateAction>
          </>
        )}
        {error instanceof NetworkError && (
          <>
            <ImgErrorNoConnection
              data-testid="error-boundary-image"
              as="EmptyStateImageSvg"
            />
            <EmptyStateAction
              data-testid="error-boundary-action-primary"
              color="black"
              onClick={() => {
                onAction()
                router.reload()
              }}
            >
              Perbarui Halaman
            </EmptyStateAction>
          </>
        )}
      </EmptyStateWithIllustration>
    </Layout>
  )
}
