import type { ResponseKinerjaProfil } from 'types/kinerja'

export const dummyKinerjaProfile: ResponseKinerjaProfil = {
  data: {
    nama: 'Sri Hariyati',
    pangkatGolongan: 'LTIII/A',
    jenjang: 'Bawah Pertama',
    satuanPendidikan: 'SMP NEGERI TESTING',
    nip: 'acc_testing_16',
    ksAktif: false,
    npsn: '12345',
    statusPegawai: 'PNS',
    kategoriPegawai: 'PNS',
    roles: [
      {
        npsn: '12345',
        type: 'GURU',
        satuanPendidikan: 'SMP NEGERI TESTING',
      },
    ],
    nuptk: 'testing_nuptk_cgp',
  },
  meta: null,
}
