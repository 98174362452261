import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { useQuery } from 'react-query'

export function useGetDeviceId() {
  return useQuery(
    ['guru-device-id'],
    async () => {
      try {
        const response = await axios.get('/api/device-id')
        return response?.data?.deviceId ?? ''
      } catch (e) {
        const message = e?.message ?? 'Unknown error'
        return { error: message }
      }
    },
    {
      onError: (error) => Sentry.captureException(error),
      staleTime: 24 * 60 * 60 * 1000, // 1 day
      cacheTime: 24 * 60 * 60 * 1000, // 1 day
      retry: false,
    }
  )
}
