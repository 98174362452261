import { useQuery, useMutation } from 'react-query'
import { stringify } from 'query-string'
import axios from 'axios'
import {
  fetchEventSource,
  type FetchEventSourceInit,
} from '@microsoft/fetch-event-source'

import {
  CustomError,
  SessionError,
  InternalServerError,
  ForbiddenError,
  ServiceUnavailableError,
  NotFoundError,
  NetworkError,
} from 'utils/customError'
import AndroidHandler from 'utils/androidHandler'
import { getSession } from 'utils/auth'
import { timeout } from 'utils/process'
import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'
import { baseUrl } from 'configs/api'

import type { AxiosRequestConfig, AxiosPromise, AxiosError } from 'axios'
import type {
  UseQueryResult,
  UseQueryOptions,
  UseMutationOptions,
  QueryKey,
  QueryFunction,
  MutationFunction,
} from 'react-query'
import type { GuruTokenSession, Session } from 'types/auth'
import type { CustomError as ICustomError } from 'types/custom-error'

type CommonErrorHandlerFn = (
  status: any,
  path: string,
  error: any,
  errorResponse?: any
) => void

export interface QueryApiConfig<TQueryFnData = unknown> {
  axiosConfig?: AxiosRequestConfig
  queryConfig?: Omit<UseQueryOptions<TQueryFnData>, 'queryKey' | 'queryFn'>
  storageKey?: string
  storageSerializer?: (any) => any
  serviceUnavailable?: boolean
  errorHandlerFn?: CommonErrorHandlerFn
  /**
   * guru token for CCT is passed through query param
   */
  tokenFromQueryParam?: string | string[]
  queryKey?: QueryKey
  queryFn?: QueryFunction<TQueryFnData, QueryKey>
}

interface EventSourceApiConfig {
  eventSourceConfig?: FetchEventSourceInit | undefined
  storageKey?: string
  storageSerializer?: (any) => any
  errorHandlerFn?: CommonErrorHandlerFn
  onMessage?: (msg?: any) => void
  onOpen?: (response?: any) => void
  onClose?: (response?: any) => void
  onError?: (err?: Error) => void
}

export interface MutationApiConfig<
  TData = unknown,
  TError = unknown,
  TVariables = void
> {
  axiosConfig?: AxiosRequestConfig
  mutationConfig?: Omit<
    UseMutationOptions<TData, TError, TVariables>,
    'mutationFn'
  >
  storageKey?: string
  storageSerializer?: (any) => any
  errorHandlerFn?: CommonErrorHandlerFn
  /**
   * guru token for CCT is passed through query param
   */
  tokenFromQueryParam?: string | string[]
  mutationFn?: MutationFunction<TData, TVariables>
}

let sessionError: ICustomError | null = null

export const handleCommonErrors = (status, path: string, error: any) => {
  const androidHandler = AndroidHandler()

  if (!androidHandler.enabled && error?.message === 'Network Error') {
    throw new NetworkError(path, error)
  }

  switch (status) {
    case 400:
      throw new InternalServerError(path, error)
    case 401:
      throw new SessionError(path, error)
    case 403:
      throw new ForbiddenError(path, error)
    case 404:
      throw new NotFoundError(path, error)
    case 500:
      throw new InternalServerError(path, error)
    case 503:
      throw new ServiceUnavailableError(path, error)
    default:
      throw error
  }
}

export function useQueryApi<TQueryFnData, TQueryFnError = AxiosError>(
  path: string,
  {
    axiosConfig = {},
    queryConfig = {},
    storageKey = APP_LOGIN_STORAGE_KEY,
    storageSerializer,
    serviceUnavailable = false,
    tokenFromQueryParam,
    errorHandlerFn = handleCommonErrors,
    queryKey = null,
    queryFn,
  }: QueryApiConfig<TQueryFnData> = {
    axiosConfig: {},
    queryConfig: {},
    storageKey: APP_LOGIN_STORAGE_KEY,
    storageSerializer: undefined,
    serviceUnavailable: false,
    tokenFromQueryParam: null,
    queryKey: null,
  }
): UseQueryResult<TQueryFnData, TQueryFnError> {
  let cQueryKey: any = []

  if (!!axiosConfig.data) cQueryKey.push(axiosConfig.data)
  if (!!axiosConfig.params) cQueryKey.push(axiosConfig.params)
  if (cQueryKey.length > 0) {
    cQueryKey = [path, ...cQueryKey]
  } else {
    cQueryKey = path
  }

  return useQuery<TQueryFnData, TQueryFnError>(
    queryKey ?? cQueryKey,
    !!queryFn
      ? queryFn
      : (): Promise<TQueryFnData> => {
          return api<TQueryFnData>(
            path,
            axiosConfig,
            storageKey,
            serviceUnavailable,
            tokenFromQueryParam,
            storageSerializer,
            errorHandlerFn
          )
        },
    {
      useErrorBoundary: true,
      ...queryConfig,
    }
  )
}

export function useMutationApi<TData, TError, TVariables>(
  path: string | ((data) => string),
  {
    axiosConfig = {},
    mutationConfig = {},
    storageKey = APP_LOGIN_STORAGE_KEY,
    storageSerializer,
    tokenFromQueryParam,
    errorHandlerFn = handleCommonErrors,
    mutationFn,
  }: MutationApiConfig<TData, TError, TVariables> = {
    axiosConfig: {},
    mutationConfig: {},
    storageKey: APP_LOGIN_STORAGE_KEY,
    storageSerializer: undefined,
    tokenFromQueryParam: null,
  }
) {
  return useMutation<TData, TError, TVariables>(
    !!mutationFn
      ? mutationFn
      : (data: any): Promise<TData> => {
          const pathApi = typeof path === 'function' ? path(data) : path
          return api<TData>(
            pathApi,
            { ...axiosConfig, data },
            storageKey,
            false,
            tokenFromQueryParam,
            storageSerializer,
            errorHandlerFn
          )
        },
    mutationConfig
  )
}

export function useEventSourceApi(
  path: string,
  method: string,
  {
    eventSourceConfig = undefined,
    storageKey = APP_LOGIN_STORAGE_KEY,
    storageSerializer = (any) => any,
    errorHandlerFn = handleCommonErrors,
    onMessage = undefined,
    onOpen = undefined,
    onClose = undefined,
    onError = undefined,
  }: EventSourceApiConfig
) {
  const session = getSession(storageKey)
  const refetch = async (params: any) => {
    // Check session validity
    const androidHandler = AndroidHandler()
    const refreshSession = androidHandler.enabled
      ? refreshSessionApp
      : refreshSessionWeb
    await refreshSession(storageKey, storageSerializer)
    guruTokenShallowValidation(storageSerializer, storageKey, path)

    await fetchEventSource(`${baseUrl}${path}`, {
      method,
      headers: {
        Authorization: `Bearer ${session.guruToken}`,
      },
      ...(Object.keys(params).length ? { body: JSON.stringify(params) } : {}),
      async onopen(response) {
        onOpen?.(response)
      },
      onmessage(msg) {
        onMessage?.(msg)
      },
      onclose() {
        if (onClose) {
          return onClose?.()
        }
        throw new Error()
      },
      onerror(err) {
        errorHandlerFn(err.status, path, err)
        if (onError) {
          return onError?.(err)
        }

        throw new Error()
      },
      ...eventSourceConfig,
    })
  }

  return {
    refetch,
  }
}

export const paramsSerializer = (params?: Record<string, any>): string =>
  stringify(params ?? {}, { arrayFormat: 'none' })

const guruTokenShallowValidation = (storageSerializer, storageKey, path) => {
  // only allow non empty token for now since there are user that use old version of apps
  // return !!token && token.indexOf('guru') === 0
  const session = storageSerializer(getSession(storageKey))
  // check if current session is using guru token and guru token is not empty and has guru prefix
  // if (
  //   session.hasOwnProperty('guruToken') &&
  //   (!session.guruToken || session.guruToken.indexOf('guru') !== 0)
  // ) {
  //   throw new SessionError(path, null)
  // }
  if (session.hasOwnProperty('guruToken') && !session.guruToken) {
    throw new SessionError(path, null)
  }
}

export async function api<T>(
  path: string,
  options: AxiosRequestConfig = {},
  storageKey: string = APP_LOGIN_STORAGE_KEY,
  serviceUnavailable: boolean = false,
  tokenFromQueryParam: string | string[] | undefined = undefined,
  storageSerializer: (any) => any = (param) => param,
  errorHandlerFn: CommonErrorHandlerFn = handleCommonErrors
): Promise<T> {
  const androidHandler = AndroidHandler()
  const requestApi = (): AxiosPromise<T> => {
    const urlProtocolRegex = /^https?:\/\//
    const isPathNotFromBackend = urlProtocolRegex.test(path)
    const url = isPathNotFromBackend ? path : `${baseUrl}${path}`

    if (!isPathNotFromBackend) {
      if (tokenFromQueryParam) {
        options.headers = {
          Authorization: `Bearer ${tokenFromQueryParam}`,
        }
      } else {
        const session = storageSerializer(getSession(storageKey))
        options.headers = {
          Authorization: `Bearer ${session.guruToken || session.accessToken}`,
        }
      }
    }

    return axios({
      ...options,
      paramsSerializer,
      url,
    })
  }

  if (serviceUnavailable) {
    throw new ServiceUnavailableError(path, null)
  }

  try {
    if (!tokenFromQueryParam) {
      const refreshSession = androidHandler.enabled
        ? refreshSessionApp
        : refreshSessionWeb
      await refreshSession(storageKey, storageSerializer)
      guruTokenShallowValidation(storageSerializer, storageKey, path)
    }
    const response = await requestApi()
    return response.data
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
      console.log(`api-error: ${path}`, JSON.stringify(error?.response))
    }

    // Force logout when session expired / un-refresh-able
    if (error instanceof SessionError && !sessionError) {
      sessionError = error

      if (androidHandler.enabled) {
        androidHandler.requestLogin()
      } else {
        let pathname = window.location.pathname || '/home'
        if (pathname === '/logout') {
          pathname = '/home'
        }

        // loopback redirect when at home cas
        if (!pathname || '/home') {
          return
        }
        window.location.href = `/logout?from=${pathname}&target=/login&error=SessionError`
      }
    }

    if (error instanceof CustomError) {
      throw error
    }

    // save navigation because error type object can be other than AxiosError
    const status = error?.response?.status
    errorHandlerFn(status, path, error, error.response)
  }
}

let refreshTokenPromise: AxiosPromise<Session> | null

export async function refreshSessionWeb(
  storageKey: string,
  storageSerializer: (any) => any = (param) => param
): Promise<Session> {
  const path = '/guru/teachers/v1alpha2/guru-token/refresh'

  try {
    const session = storageSerializer(getSession(storageKey))
    const sessionExpiryTime = new Date(session.expiredAt).getTime()
    const currentTime = new Date().getTime()
    const isTokenExpired = sessionExpiryTime < currentTime

    guruTokenShallowValidation(storageSerializer, storageKey, null)

    if (isTokenExpired) {
      const apiUrl = baseUrl + path

      let data

      if (!refreshTokenPromise) {
        const guruTokenAxiosConfig: AxiosRequestConfig = {
          url: apiUrl,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${session.guruToken}`,
          },
        }
        const axiosConfig: AxiosRequestConfig = guruTokenAxiosConfig
        refreshTokenPromise = axios(axiosConfig) as AxiosPromise<Session>
      }
      data = (await refreshTokenPromise).data
      data = data.data || data
      window.localStorage.setItem(storageKey, JSON.stringify(data))
      refreshTokenPromise = null
      return data
    } else {
      return session
    }
  } catch (error) {
    refreshTokenPromise = null
    throw new SessionError(path, error)
  }
}

export async function refreshSessionApp(
  storageKey: string,
  storageSerializer: (any) => any = (param) => param
): Promise<Session> {
  const androidHandler = AndroidHandler()

  return androidHandler.guruTokenEnabled
    ? refreshGuruTokenSessionApp(storageKey, storageSerializer)
    : refreshAccessTokenSessionApp(storageKey, storageSerializer)
}

let refreshAppSessionPromise: Promise<GuruTokenSession> | null

export async function refreshGuruTokenSessionApp(
  storageKey: string,
  storageSerializer: (any) => any = (param) => param
): Promise<Session> {
  try {
    const session = storageSerializer(getSession(storageKey))
    const sessionExpiryTime = new Date(session.expiredAt).getTime()
    const currentTime = new Date().getTime()
    const isTokenExpired = sessionExpiryTime < currentTime

    if (isTokenExpired) {
      const androidHandler = AndroidHandler()
      let data: GuruTokenSession

      if (!refreshAppSessionPromise) {
        refreshAppSessionPromise = androidHandler.getAuthData(true)
      }
      data = await refreshAppSessionPromise

      const newSession: Session = {
        accessToken: '',
        tokenType: 'Bearer',
        expiryToken: data.expiredAt,
        refreshToken: '',
        email: data.user.email,
        expiredAt: data.expiredAt,
        guruToken: data.guruToken,
        user: data.user,
      }
      window.localStorage.setItem(
        APP_LOGIN_STORAGE_KEY,
        JSON.stringify(newSession)
      )
      return newSession
    } else {
      return session
    }
  } catch (error) {
    refreshAppSessionPromise = null
    throw new SessionError('app-refresh-session-error', error)
  }
}

export async function refreshAccessTokenSessionApp(
  storageKey: string,
  storageSerializer: (any) => any = (param) => param
): Promise<Session> {
  const session = storageSerializer(getSession(storageKey))
  const sessionExpiryTime = new Date(session.expiryToken).getTime()
  const currentTime = new Date().getTime()
  const isTokenExpired = sessionExpiryTime < currentTime

  if (isTokenExpired) {
    const androidHandler = AndroidHandler()
    androidHandler.refreshToken()
    await timeout(1000)
    const accessToken = androidHandler.requestAccessToken()
    const expiredAt = new Date(new Date().getTime() + 3600 * 1000).toISOString()
    const user = JSON.parse(androidHandler.getUser())
    const newSession: Session = {
      accessToken,
      tokenType: 'Bearer',
      expiryToken: expiredAt,
      refreshToken: 'dummy-refresh-token',
      email: androidHandler.getEmail(),
      expiredAt: expiredAt,
      guruToken: '',
      user: {
        id: user.id,
        name: user.name,
        email: user.email,
        picture: user.photoUrl,
        groups: user.groups,
      },
    }
    window.localStorage.setItem(
      APP_LOGIN_STORAGE_KEY,
      JSON.stringify(newSession)
    )
    return newSession
  }
  return session
}
